:root {

    --input-color: #000;   
    --input-border: #CDD9ED; 
    --input-background: #fff; 
    --input-placeholder: #CBD1DC;
    --input-border-focus: #275EFE;   

    --group-color: var(--input-color);
    --group-border: var(--input-border);
    --group-background: #EEF4FF;

    --group-color-focus: #fff;
    --group-border-focus: var(--input-border-focus);
    --group-background-focus: #678EFE;

}
.formField {
    display: block;
    width: 100%;
    padding: 8px 16px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500; 
    font-family: inherit;
    border-radius: 6px;
    /* -webkit-appearance: none; */
    color: var(--input-color);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    transition: border 0.3s ease;
  }
  .formField::-moz-placeholder {
    color: var(--input-placeholder);
  }
  .formField:-ms-input-placeholder {
    color: var(--input-placeholder);
  }
  .formField::placeholder {
    color: var(--input-placeholder);
  }
  .formField:focus {
    outline: none;
    border-color: #18A689;
  }
  
  .formGroup {
    position: relative;
    display: flex;
    width: 315px;
    margin-top: 10px;
  }

  .formGroup > span,
  .formGroup .formField {
    white-space: nowrap;
    display: block;
  }
  .formGroup > span:not(:first-child):not(:last-child),
  .formGroup .formField:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .formGroup > span:first-child,
  .formGroup .formField:first-child {
    border-radius: 6px 0 0 6px;
  }
  .formGroup > span:last-child,
  .formGroup .formField:last-child {
    border-radius: 0 6px 6px 0;
  }
  .formGroup > span:not(:first-child),
  .formGroup .formField:not(:first-child) {
    margin-left: -1px;
  }
  .formGroup .formField {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .formGroup > span {
    text-align: center;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 25px;
    color: var(--group-color);
    background: var(--group-background);
    border: 1px solid var(--group-border);
    transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
  }
  .formGroup:focus-within > span {
    color: var(--group-color-focus);
    background: #0D7373;
    border-color: #0D7373;
  }

.form{
    display: flex;
    flex-direction: column;
    margin-top: -200px;
    position: relative;
    z-index: 40;
    gap: 20px;
}

.cadastro{
    display: flex; 
    flex-direction: column;  
    align-items: flex-end; 
    background: #F1F3F9; 
}

.mainContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 90px);
    max-height: 20000px;
    min-height: 100vh;
    box-sizing: border-box;
    margin-bottom: 120px;
    
}
.mainContent2{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  width: calc(100% - 320px);
  max-height: 20000px;
  min-height: 100vh;
  box-sizing: border-box;
  margin-bottom: 120px;
  
}

.formBlock{
    background: #ffff;
    padding: 30px;
    width: 90%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
}

.buttonEdit{
  background: #ff7f50;
  border: 1px solid #ff7f50;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 120px;
  margin-top: 12px;
}


.components{
    display: flex;
    flex-direction: column;
    gap:30px;
    width: 100%;
}

.formBlock h2{
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 22px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 10px;
}


.btn{
  width: 48px;
  height: 43px;
  background: #0D7373;
  border: 1px solid #0D7373;
  cursor: poiter;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}


.formComponents{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
    margin: 10px 0px;
    width: 100%;
  
}

.loader{
  margin-top: -20px;
  position: absolute;
  z-index: 60;
  background: #ccc;
  width: 100%;
  height: 250vh;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.loaderInterno{
opacity: 1;
position: relative;
margin-top: -900px;
z-index: 2000;
}
.formItems{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
}
.formItems2{
  display: flex;
  flex-direction: row;

}

.formButton{
    background: #0d7373;
    border: 1px solid #0d7373;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 150px;
    margin-top: 12px;
}


.formButton2{
  background: #0d7373;
  border: 1px solid #0d7373;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 110px;
  margin-top: 12px;
}

.formButtonPlus{
  background: #0d7373;
  border: 1px solid #0d7373;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 50px;
  margin-top: 12px;
}
.buttonsBloco{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 300px;

}



.formButton2{
    background: #0d7373;
    border: 1px solid #0d7373;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 100px;
    margin-top: 12px;
}

.formButtonEdit{
    background:#d95d00;
    border: 1px solid #d95d00;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 100px;
    margin-top: 12px;
}

.formButtonClose{
    background:#ff2401;
    border: 1px solid #ff2401;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 120px;
    margin-top: 12px;
}

.modalEstilo{
  margin-top: 200px;
}
.mainTable{
    margin-top: 40px;
    max-width: 1297px;
    width: 95%;
    border: none;
}

.blocoTitleTable{
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #000;
    margin-bottom: 15px;
}

.table{
   
    height: 100%;
    max-height: 1500px;
    border: 1px solid black;
    border-radius: 20px;
    background-color: #F1F3F9;
    margin-top: 30px;
}

.tableHeader{
    background-color:#F1F3F9;
    color: #000;

}

.tableName{
    background-color: #F1F3F9;
    display: flex;
    justify-content: center;
    align-items: center;
 }

 .valueTable{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 24px 10px;
    font-family: 'Inter Tight', sans-serif;
    font-size: 20px;
    background-color: #F1F3F9;
}


.valueTable2{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 12px 10px;
    font-family: 'Inter Tight', sans-serif;
    font-size: 20px;
    gap: 10px;
    background-color: #F1F3F9;
}
.blocoButton{
    display: flex;
    justify-content: flex-start;
}

.blocoButton2{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
  gap: 20px;

}

.numbers{
  padding: 20px 10px;
  font-family: 'Inter Tight', sans-serif;
  font-size: 20px;
  
}

.valueTable{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 20px 10px;
  font-family: 'Inter Tight', sans-serif;
  font-size: 20px;
}


/* Mobile */
@media screen and (max-width: 1308px) {
  
  .formComponents{
    width: 95%;

  }

  .formGroup{
    width: 300px;
  }

}

@media screen and (max-width: 1300px) {
  
  .formComponents{
    width: 90%;

  }

  .formGroup{
    width: 280px;
  }

}


@media screen and (max-width: 1285px) {
  
  .formComponents{
    width: 85%;

  }

  .formGroup{
    width: 260px;
  }

}

@media screen and (max-width: 1270px) {
  
  .formComponents{
    width: 82%;

  }

  .formGroup{
    width: 247px;
  }

}


@media screen and (max-width: 1260px) {
  
  .formComponents{
    width: 80%;
  }

  .formGroup{
    width: 238px;
  }

}

@media screen and (max-width: 1025px) {
  
  .formComponents{
    width: 75%;
  }

  .formGroup{
    width: 225px;
  }

}


@media screen and (max-width: 990px) {
  
  .formComponents{
    width: 70%;
  }

  .formGroup{
    width: 215px;
  }

}

@media screen and (max-width: 950px) {
  
  .formComponents{
    width: 65%;
  }

  .formGroup{
    width: 200px;
  }

}

@media screen and (max-width: 900px) {
  
  .formComponents{
    width: 60%;
  }

  .formGroup{
    width: 190px;
  }

}

@media screen and (max-width: 864px) {
  
  .formComponents{
    width: 55%;
  }

  .formGroup{
    width: 175px;
  }

}

@media screen and (max-width: 815px) {
  
  .formComponents{
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .formGroup{
    width: 300px;
  }

  .blocoButton2{
    margin-top: 80px;
  }

}

@media screen and (max-width: 740px) {
  
  .itemHidden{
    display: none;
  }

  .formGroup{
    width: 200px;
  }

  .formButton2, .formButtonClose{
    width: 100px;
  }

  .table{
    width: 85% !important;
  }

}
@media screen and (max-width: 575px) {
  
  .formComponents{
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .formGroup{
    width: 280px;
  }

  .blocoButton2{
    margin-top: 80px;
  }

}

@media screen and (max-width: 552px) {
  
  .formComponents{
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .formGroup{
    width: 280px;
  }

  .blocoButton2{
    margin-top: 80px;
    justify-content: center;
  }

  .formButton{
    width: 250px;
  }

}

@media screen and (max-width: 540px) {
  
  .formComponents{
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .formGroup{
    width: 260px;
  }



}

@media screen and (max-width: 520px) {
  
  .formComponents{
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .formGroup{
    width: 240px;
  }

  .mainContent{
    margin-top: 15px;
  }



}

@media screen and (max-width: 500px) {
  
  .formItems{
    flex-direction: column;
  }
  
  
  .formGroup{
    width: 290px;
  }

}

@media screen and (max-width: 466px) {
  
  .formItems{
    flex-direction: column;
  }
  
  
  .formGroup{
    width: 280px;
  }

}

@media screen and (max-width: 450px) {
  
  .formItems{
    flex-direction: column;
  }
  
  
  .formGroup{
    width: 260px;
  }
  

  .mainContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 20px);
    max-height: 20000px;
    min-height: 100vh;
    box-sizing: border-box;
    margin-bottom: 120px;
    
    
}
}


@media screen and (max-width: 420px) {
  
  .formItems{
    flex-direction: column;
  }
  
  
  .formGroup{
    width: 260px;
  }
  

  .mainContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 20px);
    max-height: 20000px;
    min-height: 100vh;
    box-sizing: border-box;
    margin-bottom: 120px;
    margin-top: 20px !important;
    
}
}



@media screen and (max-width: 376px) {
  
  .formItems{
    flex-direction: column;
  }
  
  
  .formGroup{
    width: 260px;
  }
  

  .mainContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 25px);
    max-height: 20000px;
    min-height: 100vh;
    box-sizing: border-box;
    margin-bottom: 120px;
    margin-top: -20px;
    
    
}
}

@media screen and (max-width: 360px) {
  
  .formItems{
    flex-direction: column;
  }
  
  
  .formGroup{
    width: 250px;
  }

  .formBlock h2{
    font-size: 18px;
  }
  

}
