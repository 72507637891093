:root {

  --input-color: #000; 
  --input-border: #CDD9ED; 
  --input-background: #fff; 
  --input-placeholder: #CBD1DC; 
 
  --input-border-focus: #275EFE; 

  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #EEF4FF;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: #678EFE;

}
.formField {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  /* -webkit-appearance: none; */
  color: var(--input-color);
  border: 1px solid var(--input-border);
  background: var(--input-background);
  transition: border 0.3s ease;
}
.formField::-moz-placeholder {
  color: var(--input-placeholder);
}
.formField:-ms-input-placeholder {
  color: var(--input-placeholder);
}
.formField::placeholder {
  color: var(--input-placeholder);
}
.formField:focus {
  outline: none;
  border-color: #18A689;
}

.formGroup {
  position: relative;
  display: flex;
  width: 250px;
  margin-top: 10px;
}

.formGroup2 {
  position: relative;
  display: flex;
  width: 190px;
  margin-top: 10px;
}

.formGroup3 {
  position: relative;
  display: flex;
  width: 160px;
  margin-top: 10px;
}

.formGroup4 {
  position: relative;
  display: flex;
  width: 245px;
  margin-top: 10px;
}

.formGroup > span,  .formGroup2 > span, .formGroup3 > span, .formGroup4 > span,
.formGroup .formField,  .formGroup2 .formField, .formGroup3 .formField, .formGroup4 .formField {
  white-space: nowrap;
  display: block;
}
.formGroup > span:not(:first-child):not(:last-child), .formGroup2 > span:not(:first-child):not(:last-child), .formGroup3 > span:not(:first-child):not(:last-child), .formGroup4 > span:not(:first-child):not(:last-child),
.formGroup .formField:not(:first-child):not(:last-child), .formGroup2 .formField:not(:first-child):not(:last-child), .formGroup3 .formField:not(:first-child):not(:last-child), .formGroup4 .formField:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.formGroup > span:first-child, .formGroup2 > span:first-child,  .formGroup3 > span:first-child, .formGroup4 > span:first-child,
.formGroup .formField:first-child, .formGroup2 .formField:first-child, .formGroup3 .formField:first-child, .formGroup4 .formField:first-child {
  border-radius: 6px 0 0 6px;
}
.formGroup > span:last-child,.formGroup2 > span:last-child, .formGroup3 > span:last-child, .formGroup4 > span:last-child,
.formGroup .formField:last-child, .formGroup2 .formField:last-child, .formGroup3 .formField:last-child, .formGroup4 .formField:last-child {
  border-radius: 0 6px 6px 0;
}
.formGroup > span:not(:first-child), .formGroup2 > span:not(:first-child), .formGroup3 > span:not(:first-child), .formGroup4 > span:not(:first-child),
.formGroup .formField:not(:first-child), .formGroup2 .formField:not(:first-child), .formGroup3 .formField:not(:first-child), .formGroup4 .formField:not(:first-child) {
  margin-left: -1px;
}
.formGroup .formField, .formGroup2 .formField, .formGroup3 .formField, .formGroup4 .formField {
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  width: 1%;
  margin-top: 0;
  margin-bottom: 0;
}
.formGroup > span, .formGroup2 > span, .formGroup3 > span, .formGroup4 > span {
  text-align: center;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 25px;
  color: var(--group-color);
  background: var(--group-background);
  border: 1px solid var(--group-border);
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}
.formGroup:focus-within > span, .formGroup2:focus-within > span,  .formGroup3:focus-within > span, .formGroup4:focus-within > span {
  color: var(--group-color-focus);
  background: #0D7373;
  border-color: #0D7373;
}


.listViagem{  
  display: flex; 
  flex-direction: column;
  align-items: flex-end;   
  background: #F1F3F9;
  padding-bottom: 230px;
}


.tableList{
  background: #fff;
  padding: 40px;
  width: 100%;
  border-radius: 10px;
}

.paginationList{
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: flex-end;
  padding-right: 5px;

}

.paginationItem{
  width: 25px; 
  background-color: #0D7373;
  text-align: center;
  color: #fff;
  font-size: 17px;
  border-radius: 30%;
  cursor: pointer;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.labels2{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 20px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 10px;
}

.mainContent{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  width: calc(100% - 90px);
  min-height: 100vh;
  max-height: 2000px;
  box-sizing: border-box;
  padding-bottom: 23px;
  
}

.mainContent2{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  width: calc(100% - 320px);
  min-height: 100vh;
  max-height: 2000px;
  box-sizing: border-box;
  padding-bottom: 23px;
  
}

.tableP{
  font-size: 18px;
  
}

.saidaP{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #333333;
  line-height: 38px;
  font-weight: 400;
  font-style: normal;
}

.saidaParagrafo{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #333333;
  line-height: 38px;
  font-weight: 400;
  font-style: normal;
}

input::-webkit-calendar-picker-indicator{
  display: block;
}

input::-moz-calendar-picker-indicator{
  display: block;
}

.input{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 125px;
  height: 42px;
  padding: 15px 0px 15px 7px;

}

.saidaContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding-left: 10px;
}
.inputForm{
  display: flex;
  flex-direction: column;
  gap: 6px;
}


.inputIcon{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}


.listTitle{
  margin-bottom: 40px;
}


.searchButton{
  color: #fff;
  width: 110px !important;
  height: 42px;
  background: #0D7373;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
}

.clearButton{
  color: #fff;
  width: 110px !important;
  height: 42px;
  background: #FF4742;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
}

.blockItens{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  background: #fff;
  width: 90%;
  padding: 40px;
  border-radius: 15px;
  position: relative;
  z-index: 40;
  margin-top: -200px;
}

/* Tabela */

.tableTextDetails{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #000;
  line-height: 38px;
  font-weight: 300;
  font-style: normal;
  width: 150px;
  margin: 0 auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableTextDetails2{
  font-family: 'Inter Tight', sans-serif;
  font-size: 22px;
  color: #fff;
  line-height: 38px;
  font-weight: 300;
  font-style: normal;
  width: 200px;
  margin: 0 auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainTable{
  margin-top: 40px;
  width: 90%;
  border-radius: 15px;
  position: relative;
  z-index: 40;
  overflow: hidden;
 
}

.active{
  background-color: #000;
}
.table{
  height: 225px;
  max-height: 1500px;
  border: 1px solid black;
  border-radius: 10px;

}

.tableHeader{
  background-color: #0D7373;
  color: #fff; 
}


.tableBody td{
  vertical-align: middle;
 text-align: center;
 font-family: 'Inter Tight', sans-serif;

}

.tableHeader th{
  padding: 7px 0px 4px 23px;
  font-family: 'Inter Tight', sans-serif;
  text-align: center;
}


.buttonEdit{
  padding: 5px 15px;
  background: #A9A9A9;
  font-weight: 600;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
}
.buttonAdd{
  padding: 5px 12px;
  background: #0D7373;
  font-weight: 600;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-family: 'Inter Tight', sans-serif;
}

.buttonAddModifer{
  padding: 5px 12px;
  background: #ccc;
  font-weight: 600;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-family: 'Inter Tight', sans-serif;
}
.buttonsContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 20px 0px;

}

.buttonNew{
  background: #18A689;
  color: #fff;
  width: 160px;
  height: 42px;
  border-radius: 5px;


}

.buttonNew:hover{
  background: #18A689;
  color: #fff;
}

.saidaP{
  font-size: 15px;
}


.loader{
  margin-top: -20px;
  position: absolute;
  z-index: 60;
  background: #ccc;
  width: 100%;
  height: 250vh;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.loaderInterno{
opacity: 1;
position: relative;
margin-top: -900px;
z-index: 2000;
}

.inputModal{
  width: 420px;
  height: 28px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 0.2px solid #787575;
  border-radius: 3px;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  padding: 14px 0px 14px 20px;
}

.blockItensOptions, .inputContainer{
  display: flex;
  flex-direction: row;
  gap: 27px;
  align-items: flex-end;
}

.tableTextHeader{
  font-family: 'Inter Tight', sans-serif;
  font-size: 22px;
  color: #fff;
  line-height: 38px;
  font-weight: 400;
  font-style: normal;
  padding-left: 10px;

}

.blocoParadas{
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 300px;
}

.tableText{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #333333;
  line-height: 38px;
  font-weight: 400;
  font-style: normal;
  padding-left: 10px;
}
.tableTipo{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;

}

.tableOptions{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.titlePage{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #333333;
}

.tableText{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #333333;
  line-height: 38px;
  font-weight: 400;
  font-style: normal;
}

.tableTextA{
  background: #0D7373;
  border: 1px solid #0D7373;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.tableTextB{
  font-family: 'Inter Tight', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #ffff;
  line-height: 38px;
  font-weight: 600;
  font-style: normal;
  background-color: #ff7f50;
  width: 150px;
  margin: 0 auto;
  border-radius: 3px;
}


.tableTextC{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #ffff;
  line-height: 38px;
  font-weight: 600;
  font-style: normal;
  background-color: #ff0000;
  width: 150px;
  margin: 0 auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.select{
  width: 200px;
  height: 43px;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 5px;
  padding-left: 10px;
}

.select::-ms-expand {
  display: none;
}


option[value=""][disabled] {
  display: none;
}

.optionTwo{
  display: flex;
  gap: 20px;
}

.blockItensOptions, .blockItensOptions2{
  display: flex;
  flex-direction: row;
  gap: 27px;
  align-items: flex-end;
}

.iconTable{
  display: none;
}
/* Estilos da Versão Mobile*/
/* @media screen and (max-width: 450px){ 
  .mainContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 30px);
    min-height: 100vh;
    max-height: 2000px;
    box-sizing: border-box;
    padding-bottom: 23px;
    
  }
  
} */

/* @media screen and (max-width: 1160px){ 
  .mainTable{
    margin-top: 40px;
    width: 100%;
    width: 90%;
    border-radius: 15px;
    position: relative;
    z-index: 40;

   
  }

  .teste{
    background-color: red;
  }


} */

/* @media screen and (max-width: 1130px){ 
 .optionTwo{
  flex-direction: column;
 }

 .blockItensOptions{
  align-items: flex-start;
 }

 .blockItensButton{
  margin-top: 30px;
 }

 .blockItens{
  align-items: flex-start;
 }
} */
/* 
@media screen and (max-width: 885px){ 
  .blockItensOptions2{
    flex-direction: column;
  }
 }

 @media screen and (max-width: 770px){ 
  .blockItensOptions{
    flex-direction: column;
  }

  .searchButton{
    width: 300px !important;
    margin-top: -30px;
  }

  .blockItens{
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    
  }

  .select, .input{
    width: 300px !important;
  }
 }

 @media screen and (max-width: 475px){ 
  .blockItensOptions{
    flex-direction: column;
  }

  .searchButton{
    width: 270px !important;
    margin-top: -30px;
  }

  .blockItens{
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    
  }

  .select, .input{
    width: 270px !important;
  }
 }

 @media screen and (max-width: 375px){ 
  .select, .input{
    width: 230px !important;
  }

  .searchButton{
    width: 230px !important;
    margin-top: -30px;
  }
  
}

@media screen and (max-width: 325px){  
  .select, .input{
    width: 200px !important;
  }

  .searchButton{
    width: 200px !important;
    margin-top: -30px;
  }
  
}

@media screen and (max-width: 280px){ 
  .select, .input{
    width: 180px !important;
  }

  .searchButton{
    width: 180px !important;
    margin-top: -30px;
  }
  
} */

@media screen and (max-width: 1226px){ 

  .formGroup4 {
    position: relative;
    display: flex;
    width: 240px;
    margin-top: 10px;
  }

 .inputContainer{
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-end;
  }
  .blockItensOptions{
    display: flex;
    flex-direction: row;
    gap: 27px;
    align-items: center;
  }

  
.blockItens{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  width: 90%;
  padding: 40px;
  border-radius: 15px;
  position: relative;
  z-index: 40;
  margin-top: -200px;
}

.blockItensOptions2{
  margin-top: -10px;
}
}

@media screen and (max-width: 900px){ 


 .inputContainer{
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-end;
  }
  .blockItensOptions{
    display: flex;
    flex-direction: column;
    gap: 27px;
    align-items: flex-start;
  }

  
.blockItens{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff;
  width: 90%;
  padding: 40px;
  border-radius: 15px;
  position: relative;
  z-index: 40;
  margin-top: -200px;
}

.blockItensOptions2{
  margin-top: 0px;
}
}

@media screen and (max-width: 740px){ 


  .inputContainer{
     display: flex;
     flex-direction: column;
     gap: 2px;
     align-items: flex-end;
   }
   .blockItensOptions{
     display: flex;
     flex-direction: column;
     gap: 27px;
     align-items: flex-start;
   }

   .formGroup4, .formGroup{
    position: relative;
    display: flex;
    width: 300px;
    margin-top: 10px;
  }
 
   
 .blockItens{
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   background: #fff;
   width: 90%;
   padding: 40px;
   border-radius: 15px;
   position: relative;
   z-index: 40;
   margin-top: -200px;
   gap: 20px;
 }
 
 .blockItensOptions2{
   margin-top: 0px;
 }

 .searchButton{
  color: #fff;
  width: 300px !important;
  height: 42px;
  background: #0D7373;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
}

.clearButton{
  color: #fff;
  width: 300px !important;
  height: 42px;
  background: #FF4742;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
}

 }

 @media screen and (max-width: 480px){ 




   .formGroup4, .formGroup{
    position: relative;
    display: flex;
    width: 280px;
    margin-top: 10px;
  }
 
   


 .searchButton{
  color: #fff;
  width: 280px !important;
  height: 42px;
  background: #0D7373;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
}

.clearButton{
  color: #fff;
  width: 280px !important;
  height: 42px;
  background: #FF4742;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
}

 }

 @media screen and (max-width: 450px){
  .mainContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 25px);
    min-height: 100vh;
    max-height: 2000px;
    box-sizing: border-box;
    padding-bottom: 23px;
    
  }
 }

 @media screen and (max-width: 370px){
  .formGroup4, .formGroup{
    position: relative;
    display: flex;
    width: 260px;
    margin-top: 10px;
  }

 .searchButton{
  color: #fff;
  width: 260px !important;
  height: 42px;
  background: #0D7373;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
}

.clearButton{
  color: #fff;
  width: 260px !important;
  height: 42px;
  background: #FF4742;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
}
 }

 @media screen and (max-width: 350px){
  .formGroup4, .formGroup{
    position: relative;
    display: flex;
    width: 240px;
    margin-top: 10px;
  }

 .searchButton{
  color: #fff;
  width: 240px !important;
  height: 42px;
  background: #0D7373;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
}

.clearButton{
  color: #fff;
  width: 240px !important;
  height: 42px;
  background: #FF4742;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
}
 }