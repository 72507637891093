
.main{
    display: flex;
    flex-direction: column; 
    align-items: flex-start;  
 
}

.home{
    background: #F1F3F9;
}
.mainContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% - 90px);
    height: 100vh;
    box-sizing: border-box;
    position: relative;
    z-index: 50;
    margin-top: -30px;

}

.blocoGrafico{
    margin-top: 70px;
    margin-bottom: -50px;
}
.homeMain{
    margin-left: 90px;
   margin-top: 63px;
   padding: 80px;
   flex-wrap: nowrap;
   background:  #fff;
   width: 70%;
   border-radius: 10px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   
   
}

.homeMain h2{
    font-size: 25px;
    margin-bottom: 40px;
}

.graphics{
    margin-left: -4px;
    max-width: 1264px;
    width: 100%;
}

.homeList{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
 
 

}

.homeListaItems{
    display: flex;
    flex-direction: column;
    gap: 70px;
    width: 100%;
    flex-wrap: wrap;
}


.homeListItens{
    display: flex;
    justify-content: center;
    align-items: center;
    background:  #0d7373;
    border-radius: 10px;
    gap: 11px;
    cursor: pointer;
    padding: 10px;
    width: 320px !important;
 
   
}

.homeListIcon{
    width: 36px;
    height: 36px;
    color: #fff;
    margin: 16px 0px;

}


.homeListText{

    font-size: 25px;
    line-height: 21px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 26px;
    margin-right: 55px;
    font-family: 'Inter Tight', sans-serif;
    line-height: 38px;
    font-weight: 300;
    margin: 0 auto;
}


.homeListSol{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 26px;
    margin-right: 30px;
}


.homeListMot{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 26px;
    margin-right: 37px;
}

.homeListSolit{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 26px;
    margin-right: 26px;
}

.homeListAte{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 26px;
    margin-right: 12px;
}

.homeListVia{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 26px;
    margin-right: 59px;
}

@media screen and (max-width: 450px){ 
    .homeList{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .homeListItens{
        width: 200px !important;
       }

        .mainContent{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-top: 100px;
            width: calc(100% - 30px);
            min-height: 100vh;
            max-height: 2000px;
            box-sizing: border-box;
            padding-bottom: 23px;
            overflow: hidden;
            
        }
}


@media screen and (max-width: 1400px){ 
   .homeListItens{
    width: 250px !important;
   }



}



 @media screen and (max-width: 1510px){
    .homeListItens{

        width: 280px !important;
     
       
    }
 }

 @media screen and (max-width: 1295px){
    .homeList{
        flex-direction: column;

 
    }

    .homeListaItems{
        flex-direction: row;
    }

    .homeListaItems{

        justify-content: center;
    }
    
 }

 
 @media screen and (max-width: 970px){
    .mainContent{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(100% - 90px);
        height: 100vh;
        box-sizing: border-box;
        margin-top: -150px;
        position: relative;
        z-index: 50;
        padding-bottom: 500px;
        margin-left: 60px;
    
    }

    .home{
        padding-bottom: 200px;
    }
    
 }

 @media screen and (max-width: 600px){
    .mainContent{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(100% - 20px);
        height: 100vh;
        box-sizing: border-box;
        margin-top: -250px;
        position: relative;
        z-index: 50;
        padding-bottom: 1000px;
        margin-left: 10px;
    
    }

    .home{
        padding-bottom: 200px;
    }

    .homeListaItems{
        gap: 40px;
    }
    
 }