.header{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 21px;
  padding-left: 90px;
  gap: 13px;
  max-width: 3000px;
  width: 100%; 
  /* background:  #11CDEF; */
  /* background: #20b2aa; */
  background: #0d7373;
  overflow: hidden;
  border-radius: 20px 0px 0px 20px;
  padding-top: 40px;
  padding-right: 50px;
  
}

.header2{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 21px;
  padding-left: 320px;
  gap: 13px;
  max-width: 3000px;
  width: 100%; 
  /* background:  #11CDEF; */
  /* background: #20b2aa; */
  background: #0d7373;
  overflow: hidden;
  border-radius: 20px 0px 0px 20px;
  padding-top: 40px;
  padding-right: 50px;
  
}


.blocoHeader{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.blocoInfo{
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 105px;
  right: 88px;
  padding: 15px;
  width: 300px;
  height: 350px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 5000;
  border-radius: 5px;
}
.modalEstilo{
  margin-top: 150px;
}

.mascara{
  background: #fff;
}

.loader{
  position: absolute;
  z-index: 600;
  background: #ccc;
  width: 100%;
  height: 120vh;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  margin-left: -90px;
  margin-top: -40px;
  

}

.loaderInterno{
  opacity: 1;
  position: relative;
  z-index: 2000;
  margin-bottom: 30px;
}
.arrow{
  position: absolute;
  width: 0; 
  height: 0; 
  top: 94.5px;
  right: 80px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}
.userAction{
  position: relative;
  margin: 22px 26px 26px 0px;
  cursor: pointer;
}


.userActionsSpan{
  position: absolute;
  left: 62.5%;
  right: 12.5%;
  top: 3.12%;
  bottom: 71.88%;
  width: 6px;
  height: 6px;
  background: #ff0000;
  color: #fff;
  border-radius: 100px;
  font-family:'Courier New', Courier, monospace;
}
.blocoButtons{  
  
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}


.buttonClose {
  background: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}
.buttonClose:hover{
  background: #FF4742;
  border: 1px solid #FF4742;
}

.buttonLogout {
  background: #ff4500;
  border: 1px solid #ff4500;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}
.buttonLogout:hover{
  background: #ff4500;
  border: 1px solid #ff4500;
}

.span{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.blocoDados{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.blocoItemText{
  font-size: 16px;
  font-weight: bold;
}
.blocoItem{
  display: flex;
  gap: 5px;
}
.blocoItem p{
  font-size: 16px;

}

.blocoDados{
  margin-top: -60px;
}

.borda{
  width: 110%;
  margin-left: -13px;
  border-bottom: 1px solid #ccc;
  margin-top: -70px;
}

.headerContent{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

}

#iconUser {
  font-size: 26px;
  color: #ffff;
}

.tituloEstilo{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
}


.title{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px; 
  color: #FFFFFF;
}

.icon{
  cursor: pointer;
}

.tituloPagina{
  margin-top: 30px;
}

@media screen and (max-width: 700px){
  .title{
    display: none;
  }

  .tituloEstilo{
    font-size: 25px !important;
  }
}

@media screen and (max-width: 560px){
  .icon{
    display: none;
  }
}

@media screen and (max-width: 482px){
  .tituloEstilo{
    font-size: 25px;
  }
}

@media screen and (max-width: 450px) {
  .header{

    padding-left: 40px;
  }

  .tituloEstilo{
    margin-left: -10px;
    margin-top: 27px;
  }
}
@media screen and (max-width: 426px){ 
  .tituloEstilo{
    font-size: 23px;
    margin-top: 0px;
  }
}

@media screen and (max-width: 355px){
  .tituloEstilo{
    font-size: 20px; 

  }

  .icon{
    display: none;
  }
}

@media screen and (max-width: 825px){ 

  .title{
    display: none;
  }
}

@media screen and (max-width: 470px){ 

  .tituloPagina{
    margin-top: 20px;
  }
}


@media screen and (max-width: 410px){ 

  .tituloPagina{
    margin-top: 30px;
  }
  .tituloEstilo{
    font-size: 20px !important;
  }

  .userAction, #iconUser{
    display: none;
  }
}

@media screen and (max-width: 335px){ 

  .tituloEstilo{
    font-size: 16px !important;
  }
  
}