:root {

  --input-color: #000;  
  --input-border: #CDD9ED; 
  --input-background: #fff; 
  --input-placeholder: #CBD1DC;

  --input-border-focus: #275EFE;  

  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #EEF4FF;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: #678EFE;

}
.formField {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500; 
  font-family: inherit;
  border-radius: 6px;
  /* -webkit-appearance: none; */
  color: var(--input-color);
  border: 1px solid var(--input-border);
  background: var(--input-background);
  transition: border 0.3s ease;
}
.formField::-moz-placeholder {
  color: var(--input-placeholder);
}
.formField:-ms-input-placeholder {
  color: var(--input-placeholder);
}
.formField::placeholder {
  color: var(--input-placeholder);
}
.formField:focus {
  outline: none;
  border-color: #18A689;
}

.formGroup {
  position: relative;
  display: flex;
  width: 350px;
  margin-top: 10px;
}

.formGroup1 {
  position: relative;
  display: flex;
  width: 730px;
  margin-top: 10px;
}

.formGroup2 {
  position: relative;
  display: flex;
  width: 190px;
  margin-top: 10px;
}




.blocoInputs{
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.formGroup > span, .formGroup1 > span, .formGroup2 > span,
.formGroup .formField, .formGroup1 .formField, .formGroup2 .formField {
  white-space: nowrap;
  display: block;
}
.formGroup > span:not(:first-child):not(:last-child), .formGroup1 > span:not(:first-child):not(:last-child), .formGroup2 > span:not(:first-child):not(:last-child)
.formGroup .formField:not(:first-child):not(:last-child), .formGroup1 .formField:not(:first-child):not(:last-child), .formGroup2 .formField:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.formGroup > span:first-child, .formGroup1 > span:first-child, .formGroup2 > span:first-child
.formGroup .formField:first-child, .formGroup1 .formField:first-child, .formGroup2 .formField:first-child {
  border-radius: 6px 0 0 6px;
}
.formGroup > span:last-child, .formGroup1 > span:last-child, .formGroup2 > span:last-child
.formGroup .formField:last-child, .formGroup1 .formField:last-child, .formGroup2 .formField:last-child {
  border-radius: 0 6px 6px 0;
}
.formGroup > span:not(:first-child), .formGroup1 > span:not(:first-child), .formGroup2 > span:not(:first-child)
.formGroup .formField:not(:first-child), .formGroup1 .formField:not(:first-child), .formGroup2 .formField:not(:first-child) {
  margin-left: -1px;
}
.formGroup .formField, .formGroup1 .formField, .formGroup2 .formField   {
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  width: 1%;
  margin-top: 0;
  margin-bottom: 0;
}
.formGroup > span, .formGroup1 > span, .formGroup2 > span {
  text-align: center;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 25px;
  color: var(--group-color);
  background: var(--group-background);
  border: 1px solid var(--group-border);
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}
.formGroup:focus-within > span, .formGroup1:focus-within > span, .formGroup2:focus-within > span {
  color: var(--group-color-focus);
  background: #0D7373;
  border-color: #0D7373;
}


.formItems2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.formItems2{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.avulsaViagem{ 
  display: flex;   
  flex-direction: column;   
  align-items: flex-end;
  background: #F1F3F9;
}  

.mainContent{ 
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  width: calc(100% - 90px);
  min-height: 100vh;
  max-height: 2000px;
  box-sizing: border-box;
  padding-bottom: 23px;

  
}

.loader{
  margin-top: -20px;
  position: absolute;
  z-index: 60; 
  background: #ccc;
  width: 100%;
  height: 250vh;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.loaderInterno{
opacity: 1;
position: relative;
margin-top: -900px;
z-index: 2000;
}


.inputTwo{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 100px !important;
  height: 43px;
  padding: 15px 0px 15px 7px;
}


.blocoViagem{
  background: #fff;
  width: 90%;
  padding: 40px;
  border-radius: 15px;
  position: relative;
  z-index: 40;
  margin-top: -250px;
}
.itemButtonEdit {
background: #ff4700;
border: 1px solid #ff4700;
border-radius: 6px;
box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
box-sizing: border-box;
color: #FFFFFF;
cursor: pointer;
display: inline-block;
font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
font-size: 16px;
font-weight: 800;
line-height: 16px;
min-height: 40px;
outline: 0;
padding: 12px 14px;
text-align: center;
text-rendering: geometricprecision;
text-transform: none;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
vertical-align: middle;
} 

.buttonPreviews{
  background: #0D7373;
  border: 1px solid #0D7373;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.buttonCancelViagem{
  background:  #ec2300;
  border: 1px solid  #ec2300;
  border-radius: 6px; 
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  margin-top: 20px;
}
.buttonAnt{
  background:  #ec2300;
  border: 1px solid  #ec2300;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.tableTextA{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #000;
  line-height: 38px;
  font-weight: 300;
  font-style: normal;
  width: 150px;
  margin: 0 auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableTextA2{
  font-family: 'Inter Tight', sans-serif;
  background-color: #3aae6d;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  margin-top: 28px;
}

.tableTextC{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #000;
  line-height: 38px;
  font-weight: 300;
  font-style: normal;
  width: 150px;
  margin: 0 auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.campoViagem{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;

}


.viagemTitle, .viagemTitle2{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  color: #333333;
}

.viagemTitleParadas{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 0px;
}

.tableTextB{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #000;
  line-height: 38px;
  font-weight: 300;
  font-style: normal;
  width: 150px;
  margin: 0 auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.viagemTitle2{
  margin-bottom: 30px;
}

.viagemBloco2{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.titlePage{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #333333;
}
.labelBlocos{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.inputBlocos{
  width: 350px !important;
  height: 43px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  padding: 14px 0px 14px 20px;
}

.inputDetails{
  width: 356px;
  height: 43px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 2px solid #000;
  border-top: none;
  border-right: none;
  border-left: none;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: #000;
  padding: 14px 0px 14px 5px;
}

.inputDetails:focus{
  box-shadow: 0 0 0 0;
  outline: 0;
}

.blocoInput{
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
}

.blocoInputDetails{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}
.blocoChecked{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  gap: 10px;
}

.input{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 220px !important;
  height: 42px;
  padding: 15px 0px 15px 7px;
}
.select{
  width: 350px !important;
  height: 43px;
  background: #FFFFFF;
  border-radius: 5px;
  padding-left: 10px;
}


.select::-ms-expand {
  display: none;
}
input::-webkit-calendar-picker-indicator{
  display: block;
}

.btn{
  width: 48px;
  height: 43px;
  background:#0D7373;
  border: 1px solid #0D7373;
  cursor: poiter;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  font-size: 18px;


}


.blocoButton{
  margin-top: 28px;
}

/* Tabela */


 .campoIntinerario{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  background: #fff;
  width: 90%;
  padding: 40px;
  border-radius: 15px;
}





.table{
  height: 100%;
  max-height: 1500px;
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 20px;

}

.tableHeader{
  background-color: #0D7373;
  color: #fff; 
}

.tableBody td{
  vertical-align: middle;
 text-align: center;
 font-family: 'Inter Tight', sans-serif;

}

.tableHeader th{
  padding: 7px 0px 4px 23px;
  font-family: 'Inter Tight', sans-serif;
  text-align: center;
}

.contentArea{

  height: 500px;
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 10px;


}
.containerButton{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: -60px;
}

.contentArea h3{
  font-family: 'Inter Tight', sans-serif;
  color: #000;
  font-weight: 400px;
}

.blocoEditarViagem{
  display: flex; 
  flex-direction: column;
  gap: 20px;
    margin-top: 30px;
}

.textArea{
  width: 400px !important;
  height: 400px !important;
  font-family: 'Inter Tight', sans-serif;
  font-size: 20px;
}



.modalEstilo{
  margin-top: 150px;
}


.buttonInicia{
  background: #ff4700;
  color: #fff;
  width: 87px;
  height: 35px;
  border-radius: 5px;
}

.buttonConcluido{
  background: #18A689;
  color: #fff;
  width: 87px;
  height: 35px;
  border-radius: 5px;
}

.buttonConcluido:hover{
  background: #18A689;
  color: #fff;
}
.buttonInicia:hover{
  background: #ff4700;
  color: #fff;
}

.divButton{
  display: flex;
  justify-content: center;
  align-items: center;
}





.inputModalKM{
  width: 356px;
  height: 28px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 2px;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #777777;
  padding: 14px 0px 14px 20px;
  margin-top: 10px;
}

.buttonsContent{
  max-width: 3000px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  margin-top: 35px;

}

.blocoButtonContent{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* .contentParadas{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  background: #fff;
  width: 90%;
  padding: 40px;
  border-radius: 15px;
} */

.contentParadas{
  margin: 40px 0px;
}

.contentBlocoParadas{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.inputFormCheck{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.inputForm{
  width: 350px !important;
}

.blocoOptions{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputOptions{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.optionsContent{
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 90%;
  align-items: flex-start;
  gap: 20px;

}

.inputParadas{
  padding: 5px 20px;
  width: 280px;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  background: #fff;
  border: 1px solid #808080;
}

.iconEditContent{
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconEdit{
  cursor: pointer;
}

.iconEditButton{
  cursor: pointer;
  width: 80px;
  padding: 10px;
  background-color: #ff9e00;
  color: #fff;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
}



.saidaContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.buttonsContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 20px 0px;

}

.iconEditButton2{
  cursor: pointer;
  width: 80px;
  padding: 10px;
  background-color: #CCC;
  color: #fff;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
}

.viagemBloco1{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  /* margin-top: 30px; */
  margin-bottom: 20px;
}

.blocoInput{
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
}

.labelBlocos{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

/* .inputBlocos{
  width: 356px;
  height: 43px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  padding: 14px 0px 14px 20px;
} */

.blocoChecked{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}


@media screen and (max-width: 1175px){
.inputBlocos{
  width: 300px !important;
}

.input{
  width: 180px !important;
}
}

@media screen and (max-width: 1065px){
  .inputBlocos{
    width: 270px !important;
  }
  
  .input{
    width: 160px !important;
  }
  }

  @media screen and (max-width: 1000px){
    .inputBlocos{
      width: 250px !important;
    }
    
    .input{
      width: 150px !important;
    }
    }

    @media screen and (max-width: 980px){
      .inputBlocos{
        width: 220px !important;
      }
      
      .input{
        width: 120px !important;
      }
      }

      @media screen and (max-width: 805px){
        .inputForm, .select{
          width: 300px !important;
        }
        }

        @media screen and (max-width: 750px){
          .inputForm, .select{
            width: 270px !important;
          }
          }

          @media screen and (max-width: 717px){
            .inputForm, .select{
              width: 240px !important;
            }
            }

            @media screen and (max-width: 650px){
              .inputForm, .select{
                width: 200px !important;
              }
              }

              @media screen and (max-width: 640px){
                .blocoEditarViagem{
                  flex-direction: column;
                }

                .containerButton{
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-top: -20px;
                }
                }

      @media screen and (max-width: 900px){
        .viagemBloco2{
          flex-direction: column;
          align-items: flex-start;
        }

        .inputBlocos{
          width: 300px !important;
        }
        
        .input, .inputTwo{
          width: 300px !important;
        }

     
        }

        @media screen and (max-width: 500px){
  
          .inputBlocos{
            width: 270px !important;
          }
          
          .input, .inputTwo{
            width: 270px !important;
          }
  
       
          }
/* Estilos da Versão Mobile*/
@media screen and (max-width: 450px){ 
  .mainContent{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 100px;
      width: calc(100% - 20px);
      min-height: 100vh;
      max-height: 2000px;
      box-sizing: border-box;
      padding-bottom: 23px;
      overflow: hidden;
      
  }

  .inputBlocos{
    width: 200px;
    height: 43px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #787575;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
    border-radius: 5px;
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    padding: 14px 0px 14px 20px;
  }

  .inputOptions{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .input{
    width: 200px;
  }

  .select{
    width: 200px;
  }

  .optionsContent{
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    width: 90%;
    align-items: flex-start;
    gap: 20px;
  
  }
  
.contentArea{
  max-width: 200px;
  height: 165px;
  width: 200px !important;
  display: flex;
  flex-direction: column;
  background: red;
  gap: 10px;


}


.viagemBloco2{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 20px;
}
.buttonsContent{
  flex-direction: column;
  gap: 10px;

}


.tableRemove{
  display: none;
}


.tableHeader tr th{
  width: 15%;
}

.inputParadas{
  width: 100px;
}



}