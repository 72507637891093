 .container{
  background-color: #F2F2F2;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center; 
  align-items: center;
 }

 .blocoLogin{
  width: 375px;
  height: 400px;
  background-color: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* border-radius: 10px; */
 }
.blocoMostraSenha{
   display: flex;
   justify-content: flex-start;
   align-items: center;
   gap: 10px;

}

.messagemErro{
   font-family: 'Inter Tight', sans-serif;
   color: #cc0000;
   font-size: 12px;
}
 .loader{
   position: absolute;
   z-index: 60;
   background: #ccc;
   width: 100%;
   height: 100vh;
   opacity: 0.8;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 2000;
}
.modalEstilo{
   margin-top: 150px;
}
.loaderInterno{
   opacity: 1;
   position: relative;
   z-index: 2000;
}

 .blocoLogin h1{
  font-size: 22px;
  color: #344767;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .formulario{
    margin: 5px 0px;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 30px;
    margin-bottom: 40px;
 }

 .blocoMostraSenha p{
   font-size: 14px;
 }

 .loginP{
    margin-bottom: 10px;
    font-size: 12px;
    color: #67748E;
    font-weight: 500;
 }

 .Esqueceu{
    font-size: 12px;
    color: #67748E;
    font-weight: 500;
    cursor: pointer;
 }

 .button{
    width: 100%;
    font-size: 18px;
    background-color: #0d7373 !important;
    border: none !important;
    color: #fff;
 }

 .button:hover{
    background-color: #0d7373 !important;
    border: none !important;
 }

 .labels{
    font-size: 18px;
    font-weight: 350;
    margin-bottom: 5px;
 }

 .inputForm{
    margin-bottom: 10px;
 }