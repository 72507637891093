:root {

    --input-color: #000;
    --input-border: #CDD9ED; 
    --input-background: #fff;
    --input-placeholder: #CBD1DC;

    --input-border-focus: #275EFE; 

    --group-color: var(--input-color);
    --group-border: var(--input-border);
    --group-background: #EEF4FF;

    --group-color-focus: #fff;
    --group-border-focus: var(--input-border-focus);
    --group-background-focus: #678EFE;

}
.formField {
    display: block;
    width: 100%;
    padding: 8px 16px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    border-radius: 6px;
    /* -webkit-appearance: none; */
    color: var(--input-color);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    transition: border 0.3s ease;
  }
  .formField::-moz-placeholder {
    color: var(--input-placeholder);
  }
  .formField:-ms-input-placeholder {
    color: var(--input-placeholder);
  }
  .formField::placeholder {
    color: var(--input-placeholder);
  }
  .formField:focus {
    outline: none;
    border-color: #18A689;
  }
  
  .formGroup {
    position: relative;
    display: flex;
    width: 330px;
    margin-top: 10px;
  }

  .formGroupActive {
    position: relative;
    display: flex;
    width: 280px;
    margin-top: 10px;
  }

  .formGroup > span,  .formGroupActive > span,
  .formGroup .formField, .formGroupActive .formField {
    white-space: nowrap;
    display: block;
  }
  .formGroup > span:not(:first-child):not(:last-child), .formGroupActive > span:not(:first-child):not(:last-child),
  .formGroup .formField:not(:first-child):not(:last-child),   .formGroupActive .formField:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .formGroup > span:first-child,  .formGroupActive > span:first-child,
  .formGroup .formField:first-child,  .formGroupActive .formField:first-child {
    border-radius: 6px 0 0 6px;
  }
  .formGroup > span:last-child, .formGroupActive > span:last-child,
  .formGroup .formField:last-child,  .formGroupActive .formField:last-child {
    border-radius: 0 6px 6px 0;
  }
  .formGroup > span:not(:first-child),   .formGroupActive > span:not(:first-child),
  .formGroup .formField:not(:first-child),   .formGroupActive .formField:not(:first-child) {
    margin-left: -1px;
  }
  .formGroup .formField, .formGroupActive .formField {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .formGroup > span, .formGroupActive > span {
    text-align: center;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 25px;
    color: var(--group-color);
    background: var(--group-background);
    border: 1px solid var(--group-border);
    transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
  }
  .formGroup:focus-within > span,  .formGroupActive:focus-within > span {
    color: var(--group-color-focus);
    background: #0D7373;
    border-color: #0D7373;
  }
  

.cadastroVeiculo{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background: #F1F3F9;
    padding-bottom: 200px;
}

.formVeiculo{
    margin-top: 39px;

} 
.formBlock{
    background: #ffff;
    padding: 30px;
    width: 90%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -200px;
    position: relative;
    z-index: 40;
    margin-bottom: 20px;
}

.formButton3{
    background: #0d7373;
    border: 1px solid #0d7373;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 200px;
    margin-top: 22px;

}

.formBlock2{
    background: #ffff;
    padding: 30px;
    width: 90%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
   
}
.labels{
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.formBlock h2, .formBlock2 h2{
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 22px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 10px;
}


.formBlock h2{
    margin-bottom: 40px;
}

.formComponents{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 30px;
    margin: 20px 0px;

  
}

#select::-ms-expand {
    display: none;
}

.formComponents3{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: -20px;
}

.formComponents2{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
    margin: 0px 0px;
}

.formComponents4{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    margin: 20px 0px;
}
.formItems2{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding-left: 40px;
    margin-top: 45px;
}


.input{
    width: 280px !important;
    height: 43px; 
    background: #FFFFFF;
    border-radius: 5px;
    margin: 8px 0px;
    padding: 0px 0px 0px 20px;
 
}

.messagemErro{
    display: block;
    font-family: 'Inter Tight', sans-serif;
    color: #cc0000;
}

.loader{
    margin-top: -20px;
    position: absolute;
    z-index: 60;
    background: #ccc;
    width: 100%;
    height: 250vh;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
 }
 .loaderInterno{
  opacity: 1;
  position: relative;
  margin-top: -900px;
  z-index: 2000;
}


.inputForm{
    width: 330px !important;
    height: 43px;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 8px 0px;
    padding: 0px 0px 0px 20px;
}
.formItems{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.formItems3{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
}
.mainContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    padding-bottom: 100px;
    width: calc(100% - 90px);
    min-height: 100vh;
    max-height: 3000px;
    height: 100%;
    box-sizing: border-box;
}

.mainContent2{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    padding-bottom: 100px;
    width: calc(100% - 320px);
    min-height: 100vh;
    max-height: 3000px;
    height: 100%;
    box-sizing: border-box;
}

.titleForm{
    max-width: 190px;
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #C7C7C7;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0);
    border-radius: 10px 10px 0px 0px;
    padding: 15px 23px 32px 23px;
    border-bottom: none;
    z-index: 1;
    position: relative;

}

.formDetails{
    width: 125px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}



@media screen and (min-width: 1600px){
    .formBlock, .formBlock2{
        width: 80%;
   
    }
}


/* Mobile - Cadastro de Veículo*/

/* Mobile do Input ---------------------------- */
@media screen and (max-width: 1322px){
    .formGroup{
        width: 300px !important;
    }

}

@media screen and (max-width: 1245px){
    .formGroup{
        width: 280px !important;
    }

}

@media screen and (max-width: 1176px){
    .formGroup{
        width: 260px !important;
    }

}

@media screen and (max-width: 1108px){
    .formGroup{
        width: 250px !important;
    }

}

@media screen and (max-width: 1075px){
    .formGroup{
        width: 230px !important;
    }

}

@media screen and (max-width: 1015px){
    .formGroup{
        width: 220px !important;
    }

}

@media screen and (max-width: 980px){
    .formComponents{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        margin: 20px 0px; 
    }

    .formComponents4{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        margin: 20px 0px; 
    }

    .formGroup{
        width: 450px !important;
    }

}

@media screen and (max-width: 656px){
    .formGroup{
        width: 380px !important;
    }

    .formItems3{
        justify-content: center;
    }

}

@media screen and (max-width: 580px){
    .formGroup{
        width: 350px !important;
    }

}

@media screen and (max-width: 540px){
    .formGroup{
        width: 320px !important;
    }

}

@media screen and (max-width: 510px){
    .formGroup{
        width: 300px !important;
    }

}

@media screen and (max-width: 480px){
    .formGroup{
        width: 285px !important;
    }

}

@media screen and (max-width: 462px){
    .formGroup{
        width: 260px !important;
    }

}

@media screen and (max-width: 450px){
    .formGroup{
        width: 300px !important;
    }

    .mainContent{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 100px;
        padding-bottom: 100px;
        width: calc(100% - 25px);
        min-height: 100vh;
        max-height: 3000px;
        height: 100%;
        box-sizing: border-box;
        margin-top: 20px;
    }
}

@media screen and (max-width: 418px){
    .formGroup{
        width: 280px !important;
    }

}

@media screen and (max-width: 396px){
    .formGroup{
        width: 260px !important;
    }

}

@media screen and (max-width: 375px){
    .formGroup{
        width: 240px !important;
    }

}


@media screen and (max-width: 340px){
    .formGroup{
        width: 225px !important;
    }

}


/* Mobile do Input ---------------------------- */

