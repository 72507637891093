.avulsaViagem{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #F1F3F9;
}

.mainContent{
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  width: calc(100% - 90px);
  min-height: 100vh;
  max-height: 2000px;
  box-sizing: border-box;
  padding-bottom: 23px;

  
}

.mainContent2{
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  width: calc(100% - 320px);
  min-height: 100vh;
  max-height: 2000px;
  box-sizing: border-box;
  padding-bottom: 23px;

  
}

.tableTextA{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #ffff;
  line-height: 38px;
  font-weight: 600;
  font-style: normal;
  background-color: #3aae6d;
  width: 150px;
  margin: 0 auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}




.tableTextC{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #ffff;
  line-height: 38px;
  font-weight: 600;
  font-style: normal;
  background-color: #ff0000;
  width: 150px;
  margin: 0 auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableTextB{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #ffff;
  line-height: 38px;
  font-weight: 600;
  font-style: normal;
  background-color: #ff7f50;
  width: 150px;
  margin: 0 auto;
  border-radius: 3px;
}

.titlePage{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #333333;
}
.campoViagem{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
  margin-top: -200px;
  background: #fff;
  position: relative;
  z-index: 40;
  width: 90%;
  padding: 40px;
  border-radius: 15px;
}

.labelTitle{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 22px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 5px;
  margin-top: 30px;
}

.blocoInput{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.viagemBloco2{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.blocoDetalhes{
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-evenly;
  width: 100%;
}

.viagemTitle, .viagemTitle2{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
}

.viagemTitle2{
  margin-bottom: 30px;
}

.viagemBloco1{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  margin-top: 30px;
}

.labelBlocos{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.cardTitle{

  background: #0D7373;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardsContent{
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contentArea{
  max-width: 1297px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  margin-top: 20px;

}

.cardsItems{
  font-family: 'Inter Tight', sans-serif;
  color: #000;
  font-size: 18px;
}

.cardTexto{
  font-family: 'Inter Tight', sans-serif;
  color: #fff;
  font-size: 20px;
}
.cardParada{
  width: 300px;
  margin: 20px 0px;
  background: #F1F3F9;
  border-radius: 15px;
}

.inputBlocos{
  width: 356px;
  height: 43px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #777777;
  padding: 14px 0px 14px 20px;
}

.inputModal{
  width: 420px;
  height: 28px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 0.2px solid #787575;
  border-radius: 3px;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  padding: 14px 0px 14px 20px;
}

.blocoInput{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.tableTextB{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #ffff;
  line-height: 38px;
  font-weight: 600;
  font-style: normal;
  background-color: #ff7f50;
  width: 150px;
  margin: 0 auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputDetails{
  width: 150px;
  height: 43px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-bottom: none;
  border-top: none;
  border-right: none;
  border-left: none;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: #000;
  padding: 14px 0px 14px 5px;
}

.inputDetails:focus{
  box-shadow: 0 0 0 0;
  outline: 0;
}

.blocoChecked{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.input{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 125px;
  height: 42px;
  padding: 15px 0px 15px 7px;
}

input::-webkit-calendar-picker-indicator{
  display: block;
}

.btn{
  width: 48px;
  height: 43px;
  background: #18A689;
  border: 1px solid #18A689;
  cursor: poiter;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  font-size: 18px;


}

.btn:hover{
  background: #18A689;
  color: #fff;
}

.blocoButton{
  margin-top: 28px;
}

/* Tabela */



.table{
  height: 100%;
  max-height: 1500px;
  border: 1px solid black;
  border-radius: 10px;

}

.mainTable{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.tableHeader{
  background-color: #18A689; 
  color: #fff; 
}

.tableBody td{
  vertical-align: middle;
 text-align: center;
 font-family: 'Inter Tight', sans-serif;

}

.tableHeader th{
  padding: 7px 0px 4px 23px;
  font-family: 'Inter Tight', sans-serif;
  text-align: center;
}



.textArea{
  height: 150px;
  max-width: 92%;
}

.buttonPreviews{
  background: #0D7373;
  color: #fff;
  width: 160px;
  height: 42px;
  border-radius: 5px;
  font-family: 'Inter Tight', sans-serif;



}


.contentButton{
  max-width: 1297px;
  width: 92%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 30px;
}

.buttonInicio{
  background: #18A689;
  color: #fff;
  width: 160px;
  height: 42px;
  border-radius: 5px;
}

.divInicio{
  margin-top: 30px;
}

@media screen and (max-width: 450px){ 

  .mainContent{
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 30px);
    min-height: 100vh;
    max-height: 2000px;
    box-sizing: border-box;
    padding-bottom: 23px;

    
  }

  .contentButton{
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

