:root {
 
    --input-color: #000;  
    --input-border: #CDD9ED; 
    --input-background: #fff;  
    --input-placeholder: #CBD1DC;

    --input-border-focus: #275EFE; 

    --group-color: var(--input-color);
    --group-border: var(--input-border);
    --group-background: #EEF4FF;

    --group-color-focus: #fff;
    --group-border-focus: var(--input-border-focus);
    --group-background-focus: #678EFE;

}
.formField {
    display: block;
    width: 100%;
    padding: 8px 16px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500; 
    font-family: inherit;
    border-radius: 6px;
    /* -webkit-appearance: none; */
    color: var(--input-color);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    transition: border 0.3s ease;
  }
  .formField::-moz-placeholder {
    color: var(--input-placeholder);
  }
  .formField:-ms-input-placeholder {
    color: var(--input-placeholder);
  }
  .formField::placeholder {
    color: var(--input-placeholder);
  }
  .formField:focus {
    outline: none;
    border-color: #18A689;
  }
  
  .formGroup {
    position: relative;
    display: flex;
    width: 450px;
    margin-top: 10px;
  }

  .formGroup > span,
  .formGroup .formField {
    white-space: nowrap;
    display: block;
  }
  .formGroup > span:not(:first-child):not(:last-child),
  .formGroup .formField:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .formGroup > span:first-child,
  .formGroup .formField:first-child {
    border-radius: 6px 0 0 6px;
  }
  .formGroup > span:last-child,
  .formGroup .formField:last-child {
    border-radius: 0 6px 6px 0;
  }
  .formGroup > span:not(:first-child),
  .formGroup .formField:not(:first-child) {
    margin-left: -1px;
  }
  .formGroup .formField {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .formGroup > span {
    text-align: center;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 25px;
    color: var(--group-color);
    background: var(--group-background);
    border: 1px solid var(--group-border);
    transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
  }
  .formGroup:focus-within > span {
    color: var(--group-color-focus);
    background: #0D7373;
    border-color: #0D7373;
  }

.cadastroDadosVeiculo{
    display: flex; 
    flex-direction: column; 
    align-items: flex-end; 
    background: #F1F3F9;
    padding-bottom: 200px;
    /* height: 100vh; */
}

.mainContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    padding-bottom: 100px;
    width: calc(100% - 90px);
    min-height: 100vh;
    max-height: 3000px;
    height: 100%;
    box-sizing: border-box;
}

.blocoTableBorder{
   border-bottom: 1px solid #dedede;

}

.formBlock{
    /* background: #ffff;
    padding: 30px; */
    width: 95%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -250px;
    position: relative;
    z-index: 40;
    padding-bottom: 25px;
}

.formBlock h2{
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 22px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 10px;
    margin-bottom: 30px;
}

.labels{
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.inputForm{
    /* width: 600px !important;
    height: 43px;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 8px 0px;
    padding: 0px 0px 0px 20px;
    margin-left: 200px;
    border-top: none; */
    width: 600px !important;
    padding: 10px;
    border: 0;
    border-bottom: 2px solid rgb(200,200,200);
    outline: 0;
    font-size: 16px;
    color: rgb(80,80,80);
    transition: 0.5s ease-in-out;

}

.inputForm:focus,
.inputForm:valid{
    border-bottom: 2px solid #0d7373;
}

.inputForm:focus ~ label,
.inputForm:valid ~ label{
    transform: translateY(-24px);
    font-size: 12px;
    color: #0d7373;
}
.inputBloco{
    position: relative;
    margin: 30px 0;
}

.inputBloco label{
    position: absolute;
    bottom: 15px;
    left: 0;
    color: rgb(150,150,150);
    cursor: text;
    transition: 0.5s ease-in-out;
}


.formBlockComponentes{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 97%;
}


.button {
  background:#0d7373 !important;
  border: 1px solid #0d7373 !important;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.buttonUpdate{
    background:#0d7373 !important;
    border: 1px solid #0d7373 !important;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 110px;
}
.buttonUpdate2{
    background:#0d7373 !important;
    border: 1px solid #0d7373 !important;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 120px;
}

.buttonUpdate3{
    background:#0d7373 !important;
    border: 1px solid #0d7373 !important;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 220px;
}
.buttonRemove{
    background:#cc0000 !important;
    border: 1px solid #cc0000 !important;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 110px;
}

.blocoButtons{
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: #fff;
}
#select::-ms-expand {
    display: none;
}

.formBlockComponentesInterno{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.formBlockButton{
    width: 97%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 50px;
}

.mainTable{ 
    margin-top: 40px;
    width: 95%;
    background-color: #fff;
    border: none;
    border-radius: 15px;
    padding-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.modalEstilo{
    margin-top: 150px;
}


.tableHeader{
    color: #000;

}



.tableName{
    background-color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    font-size: 20px;
 }

 .valueTable{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 10px 0px;
    font-family: 'Inter Tight', sans-serif;
    font-size: 20px;
    border: none;

    
}

.componentTable{
    width: 600px;

}

.inputForm2{
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.valuesTable{
    border: none;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 15px;

}

.blocoAdiciona{
    width:   86%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: 120px;
    height: 70px; 




}
.table{
    width: 100%;

}




@media screen and (max-width: 1220px){
  .formBlockComponentes{
    width: 70%;
    flex-wrap: wrap;
    gap: 20px;
  }

}


@media screen and (max-width: 600px){
    .button{
        width: 290px;
  
    }

    .formBlockButton{
        display: flex;
        justify-content: flex-start;
        
    }
  }
  
  @media screen and (max-width: 450px){
    .mainContent{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 100px;
        padding-bottom: 100px;
        width: calc(100% - 25px);
        min-height: 100vh;
        max-height: 3000px;
        height: 100%;
        box-sizing: border-box;
    }
  }

  @media screen and (max-width: 383px){
    .formBlock{

        margin-top: -180px;
        
    }


  }
/* 
.loader2{
    position: absolute;
    z-index: 2000;
    margin: 0 auto;
    width: 100px;
    top: 500px;
    background-color: red;

} */
/* .loader3{
    position: relative;
    z-index: 2000;
    width: 100px;
    margin: 0 auto;
    
    

} */



.loader{
    position: absolute;
    z-index: 2000;
    margin: 0 auto;
    width: 100%;
    top: 300px;
}


/* Medias Queries */

@media screen and (max-width: 1062px){
  
 }