:root { 

  --input-color: #000; 
  --input-border: #CDD9ED; 
  --input-background: #fff;  
  --input-placeholder: #CBD1DC;  
 
  --input-border-focus: #275EFE; 

  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #EEF4FF;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: #678EFE;

}
.formField {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  /* -webkit-appearance: none; */
  color: var(--input-color);
  border: 1px solid var(--input-border);
  background: var(--input-background);
  transition: border 0.3s ease;
}
.formField::-moz-placeholder {
  color: var(--input-placeholder);
}
.formField:-ms-input-placeholder {
  color: var(--input-placeholder);
}
.formField::placeholder {
  color: var(--input-placeholder);
}
.formField:focus {
  outline: none;
  border-color: #18A689;
}

.formGroup {
  position: relative;
  display: flex;
  width: 350px;
  margin-top: 10px;
}

.formGroup2 {
  position: relative;
  display: flex;
  width: 190px;
  margin-top: 10px;
}

.formGroup3 {
  position: relative;
  display: flex;
  width: 160px;
  margin-top: 10px;
}

.formGroup4 {
  position: relative;
  display: flex;
  width: 245px;
  margin-top: 10px;
}

.formGroup > span,  .formGroup2 > span, .formGroup3 > span, .formGroup4 > span,
.formGroup .formField,  .formGroup2 .formField, .formGroup3 .formField, .formGroup4 .formField {
  white-space: nowrap;
  display: block;
}
.formGroup > span:not(:first-child):not(:last-child), .formGroup2 > span:not(:first-child):not(:last-child), .formGroup3 > span:not(:first-child):not(:last-child), .formGroup4 > span:not(:first-child):not(:last-child),
.formGroup .formField:not(:first-child):not(:last-child), .formGroup2 .formField:not(:first-child):not(:last-child), .formGroup3 .formField:not(:first-child):not(:last-child), .formGroup4 .formField:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.formGroup > span:first-child, .formGroup2 > span:first-child,  .formGroup3 > span:first-child, .formGroup4 > span:first-child,
.formGroup .formField:first-child, .formGroup2 .formField:first-child, .formGroup3 .formField:first-child, .formGroup4 .formField:first-child {
  border-radius: 6px 0 0 6px;
}
.formGroup > span:last-child,.formGroup2 > span:last-child, .formGroup3 > span:last-child, .formGroup4 > span:last-child,
.formGroup .formField:last-child, .formGroup2 .formField:last-child, .formGroup3 .formField:last-child, .formGroup4 .formField:last-child {
  border-radius: 0 6px 6px 0;
}
.formGroup > span:not(:first-child), .formGroup2 > span:not(:first-child), .formGroup3 > span:not(:first-child), .formGroup4 > span:not(:first-child),
.formGroup .formField:not(:first-child), .formGroup2 .formField:not(:first-child), .formGroup3 .formField:not(:first-child), .formGroup4 .formField:not(:first-child) {
  margin-left: -1px;
}
.formGroup .formField, .formGroup2 .formField, .formGroup3 .formField, .formGroup4 .formField {
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  width: 1%;
  margin-top: 0;
  margin-bottom: 0;
}
.formGroup > span, .formGroup2 > span, .formGroup3 > span, .formGroup4 > span {
  text-align: center;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 25px;
  color: var(--group-color);
  background: var(--group-background);
  border: 1px solid var(--group-border);
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}
.formGroup:focus-within > span, .formGroup2:focus-within > span,  .formGroup3:focus-within > span, .formGroup4:focus-within > span {
  color: var(--group-color-focus);
  background: #0D7373;
  border-color: #0D7373;
}
.avulsaViagem{
    display: flex;
    flex-direction: column; 
    align-items: flex-end;
    background: #F1F3F9;
  }
  
  .mainContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 90px);
    min-height: 100vh;
    max-height: 2000px;
    box-sizing: border-box;
    padding-bottom: 23px;
    
  }

  .mainContent2{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 320px);
    min-height: 100vh;
    max-height: 2000px;
    box-sizing: border-box;
    padding-bottom: 23px;
    
  }
  
  .loader{
    margin-top: -20px;
    position: absolute;
    z-index: 60;
    background: #ccc;
    width: 100%;
    height: 350vh;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
 }


 .loaderInterno{
  opacity: 1;
  position: relative;
  margin-top: -900px;
  z-index: 2000;
}

.viagemBloco2{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.contentParadas{
  margin: 40px 0px;
}
.viagemTitleParadas{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 0px;
}

.modalEstilo{
  margin-top: 120px;
}
.tableTextC{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #ffff;
  line-height: 38px;
  font-weight: 600;
  font-style: normal;
  background-color: #ff0000;
  width: 85px;
  margin: 0 auto;
  border-radius: 3px;
  cursor: pointer;
}

.tableText{
  font-family: 'Inter Tight', sans-serif;
  font-size: 18px;
  color: #000;
  line-height: 38px;
  font-weight: 600;
  font-style: normal;
  background-color: #ccc;
  width: 85px;
  margin: 0 auto;
  border-radius: 3px;
  cursor: pointer;
}
.campoViagem{
    margin-top: 40px;
  }
  
  
  .viagemTitle, .viagemTitle2{
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    color: #333333;
  }
  
  .viagemTitle2{
    margin-bottom: 20px;
  }
  
  .viagemBloco1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    margin-top: 30px;
    margin-bottom: 20px;
    background: #fff;
    padding: 40px;
    border-radius: 15px;
    width: 90%;
    margin-top: -250px;
    position: relative;
    z-index: 40;
  }

.contentParadas{
  display: flex;
  flex-direction: column;
  gap: 0px;
  background: #fff;
  width: 90%;
  padding: 40px;
  border-radius: 15px;
}

  .formIniciar{
    border-radius: 15px;
    width: 90%;
     margin-top: 30px;
    position: relative;
    z-index: 40;
    
  }
.viagemBloco3{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;

}
  .viagemBloco2Content{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
  
  .labelBlocos{
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  
  .inputBlocos{
    /* width: 356px !important; */
    width: 356px !important;
    height: 43px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #787575;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
    border-radius: 5px;
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    padding: 14px 0px 14px 20px;
  }

  .inputDetails{
    width: 250px;
    height: 43px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 2px solid #000;
    border-top: none;
    border-right: none;
    border-left: none;
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    color: #000;
    padding: 14px 0px 14px 5px;
  }

  .inputDetails:focus{
    box-shadow: 0 0 0 0;
    outline: 0;
  }
  
  .blocoInput{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
  }

  .blocoInputDetails{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    /* flex-wrap: wrap; */
  }
  .blocoChecked{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    gap: 10px;
  }
  
  .input, .inputSaida{
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-radius: 5px;
    width: 180px !important;
    height: 42px;
    padding: 15px 0px 15px 7px;
  }
  
  input::-webkit-calendar-picker-indicator{
    display: block;
  }
  
  .btn{
    width: 48px;
    height: 43px;
    background: #0D7373 !important;
    border: 1px solid #0D7373 !important;
    cursor: poiter;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
  
  
  }
  
  .btn:hover{
    background: #18A689;
    color: #fff;
  }
  
  .blocoButton{
    margin-top: 28px;
  }
  
  /* Tabela */
  .campoIntinerario{
    margin-top: -100px;
  }
  
 .campoIntinerario{
    width: 100%;
  }

  .mainTable{
    width: 100%;
  }
  
  
  
  .table{
    height: 100%;
    max-height: 1500px;
    border: 1px solid black;
    border-radius: 10px;
  
  }

  .tableTextB{
    font-family: 'Inter Tight', sans-serif;
    font-size: 18px;
    color: #000;
    line-height: 38px;
    font-weight: 300;
    font-style: normal;
    width: 150px;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
 
    


  }


  
 
.tableHeader{
  background-color: #0D7373;
  color: #fff; 
}
  
  .tableBody td{
    vertical-align: middle;
   text-align: center;
   font-family: 'Inter Tight', sans-serif;
  
  }
  
  .tableHeader th{
    /* padding: 7px 0px 4px 23px; */
    font-family: 'Inter Tight', sans-serif;
    text-align: center;
  }
  
  .contentArea{
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

  
  }

  .contentArea h3{
    font-family: 'Inter Tight', sans-serif;
    color: #000;
    font-weight: 400px;
  }
  
  .textArea{
    color: #000;
    border-radius: 5px;
    width: 50%;
    max-width: 92%;
    
  }
  
  .buttonPreviews{
    background: #18A689;
    color: #fff;
    width: 160px;
    height: 42px;
    border-radius: 5px;
  }

  .buttonInicia {
    background-color: #0D7373;
    border: 1px solid #0D7373;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 120px;
  }
/*   
  .buttonInicia:hover,
  .buttonInicia:active {
    background-color: initial;
    background-position: 0 0;
    color: #27ae60;
  }
  
  .buttonInicia:active {
    opacity: .5;
  } */


  .buttonInicia2{
    background: #ff0000;
    color: #fff;
    width: 120px;
    height: 35px;
    border-radius: 5px;
  }




.buttonInicia3 {
  background-color: #ec2300;
  border: 1px solid transparent;
  border-radius: .75rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-weight: 600;
  line-height: 1.5rem;
  padding: .75rem 1.2rem;
  text-align: center;
  text-decoration: none #6B7280 solid;
  text-decoration-thickness: auto;
  transition-duration: .2s;
  transition-property: background-color,border-color,color,fill,stroke;
  transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 120px;
  font-size: 1rem
}

.buttonInicia3:hover, .buttonInicia4 {
  background-color: #ec2300 ;
}

.buttonInicia3:focus, .buttonInicia4:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  .buttonInicia3, .buttonInicia4 {
    padding: .75rem 1.5rem;
  }
}

  .buttonInicia4{
    background-color: #ec2300;
    border: 1px solid transparent;
    border-radius: .75rem;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    flex: 0 0 auto;
    font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: 600;
    line-height: 1.5rem;
    padding: .75rem 1.2rem;
    text-align: center;
    text-decoration: none #6B7280 solid;
    text-decoration-thickness: auto;
    transition-duration: .2s;
    transition-property: background-color,border-color,color,fill,stroke;
    transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 180px;
    font-size: 1rem
  }

  .buttonIniciaDisable{
    background-color: #ccc;
    border: 1px solid transparent;
    border-radius: .75rem;
    box-sizing: border-box;
    color: #000;
    cursor: pointer;
    flex: 0 0 auto;
    font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: 600;
    line-height: 1.5rem;
    padding: .75rem 1.2rem;
    text-align: center;
    text-decoration: none #6B7280 solid;
    text-decoration-thickness: auto;
    transition-duration: .2s;
    transition-property: background-color,border-color,color,fill,stroke;
    transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 180px;
    font-size: 1rem
  }

  .buttonConcluido, .buttonConcluido2{
    background-color: #27ae60;
    border: 1px solid #27ae60;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
  }

  .buttonClose, .buttonClose2{
   
    background: #ff0000;
    border: 1px solid #ff0000;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 120px;
  }

  .buttonCloseDisable{
    background: #ff0000;
    border: 1px solid #ff0000;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
  }

  .buttonCloseDisable:hover{
    background-color: #ccc;
    color: #000;
    border: #ccc;
  }

  /* .buttonClose:hover,
  .buttonClose:active {
    background-color: initial;
    background-position: 0 0;
    color: #ff0000;
  }
  
  .buttonClose:active {
    opacity: .5;
  } */

  /* .buttonConcluido:hover,
.buttonConcluido:active {
  background-color: initial;
  background-position: 0 0;
  color: #27ae60;
}

.buttonConcluido:active {
  opacity: .5;
} */

  .divButton{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .buttonPreviews:hover{
    background: #18A689;
    color: #fff;
  }
  
  .contentButton{
    max-width: 1297px;
    width: 92%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 30px;
  }

  

  .inputModalKM{
    width: 356px;
    height: 28px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #787575;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
    border-radius: 2px;
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #777777;
    padding: 14px 0px 14px 20px;
    margin-top: 10px;
  }
  .inputFormCheck{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
 .blocoFinal{
  width: 90%;
  display: flex;
  justify-content: flex-end;
 }
.buttonAdd{
  padding: 5px 12px;
  background: #0D7373;
  font-weight: 600;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-family: 'Inter Tight', sans-serif;
  width: 120px !important;
}
.inputViagem{
  width: 200px !important;
  border-radius: 5px;
  text-align: flex-start;
  background: #fff;
  border: 1px solid #808080;
}

.inputParadas{
  padding: 5px 20px;
  width: 170px !important;
  border-radius: 5px;
  text-align: flex-start;
  background: #fff;
  border: 1px solid #808080;
}

.iniciaBotao2{
  cursor: pointer;
}

 .titlePage{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #333333;
}
.bloco1, .bloco2 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media screen and (max-width: 1245px){
 .inputBlocos{
  width: 300px !important;
 }

 .input, .inputSaida {
  width: 170px !important;
 }
  
}

@media screen and (max-width: 1186px){
  .blocoChecked{
    flex-direction: column;
  }
}

@media screen and (max-width: 1360px){
  .table{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .inputViage{
    width: 300px !important;
  }

  /* .tableHeader {
    display: flex;
    flex-direction: column;
  } */

  .tableTH, .tableTH2{
    display: flex;
    flex-direction: column;
    align-items: center;
    

    
  }

  .tableTH th, .tableTH2 th{
    display: flex;
    flex-direction: row;
    border: none;
    justify-content: center;

   
  }

  .tableBody tr{
    display: flex;
    flex-direction: column;
  }

  .tableBody{
    width: 80%;
    border: 1px solid #0D7373;
    border-bottom: none;
  }

  .tableHeader{
    width:20%;
    padding: 12px 0px;

  }

  .tableTH2 th{
    padding: 8.5px 0px;
  }

  
}


@media screen and (max-width: 1140px){
  /* #inputAlteracao{
    margin-top: -20px;
  } */

  .blocoInput{
    width: 100%;
  }

  
}

@media screen and (max-width: 1110px){

  .inputBlocos{
    width: 280px !important;
  }

  .input, .inputSaida {
    width: 160px !important;
  }

  
}

@media screen and (max-width: 1060px){

 .viagemBloco2Content{
  flex-direction: column;
  justify-content: flex-start;
 }

  .bloco1, .bloco2{
    display: flex;
    flex-direction: flex-start;
  }

  .bloco2{
    width: 100%;
  }

  .blocoButton{
    align-items: flex-start;
    width: 100%;
  }
}

@media screen and (max-width: 685px){
  .bloco1{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .input{
    width: 350px !important;
  }
  .inputBlocos{
    width: 350px !important;
  }

  .inputSaida{
    width: 200px !important;
  }
 }

 @media screen and (max-width: 640px){
  .viagemBloco3{
    flex-direction: column;
  }

  .campoIntinerario{
    margin-top:-20px;
  }
 }
 

 @media screen and (max-width: 575px){
  .bloco1{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .input{
    width: 300px !important;
  }
  .inputBlocos{
    width: 300px !important;
  }

  .inputSaida{
    width: 150px !important;
  }


 }

 @media screen and (max-width: 555px){
    .tableTH th, .tableTH2 th{
      font-size: 12px;
    }

    .tableTH th{
      padding: 8.2px 0px;
    }

    .tableTH2 th{
      padding: 11.8px 0px;
    }
 }
 

 @media screen and (max-width: 510px){
  .bloco1{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .input{
    width: 280px !important;
  }
  .inputBlocos{
    width: 280px !important;
  }

  .inputSaida{
    width: 120px !important;
  }
 }

 @media screen and (max-width: 460px){
  .bloco2{
    flex-direction: column;
    align-items: flex-start;
  }

  .blocoChecked{
    flex-direction: row;
  }

  .inputSaida{
    width: 200px !important;
  }

  .inputBlocos, .input{
    width: 200px !important;
  }
 }

 @media screen and (max-width: 450px){
  
  .mainContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 30px);
    min-height: 100vh;
    max-height: 2000px;
    box-sizing: border-box;
    padding-bottom: 230px;
    
  }
  
  .buttonInicia, .buttonClose{
    padding: 8px 10px;
  }

  .inputViagem{
    width: 160px !important;
  }

  .inputBlocos, .input, .inputSaida{
    width: 230px !important;
  }

  .blocoButton{
    align-items: flex-end;
  }
  .tableTH2 th{
    font-size: 11px;
  }

  .tableHeader th{
    font-size: 11px;
  
  }
 }

 @media screen and (max-width: 555px){
  
 .tableHeader{
  width:20%;
  padding: 38.5px 0px;

}


.tableTH2{
  padding: 28px 0px;
}

  
 }

 @media screen and (max-width: 1230px){
  
  .formGroup {

    width: 330px;
   
  }
 }

 
 @media screen and (max-width: 1200px){
  
  .formGroup {

    width: 310px;
   
  }
 }

 @media screen and (max-width: 1172px){
  
  .formGroup {

    width: 290px;
   
  }
 }

 @media screen and (max-width: 1155px){
  
  .formGroup {

    width: 270px;
   
  }
 }

 @media screen and (max-width: 1130px){
  
  .formGroup {

    width: 250px;
   
  }
 }

 @media screen and (max-width: 1110px){
  
  .formGroup {

    width: 230px;
   
  }
 }

 @media screen and (max-width: 1087px){
  
  .formGroup {

    width: 210px;
   
  }
 }

 @media screen and (max-width: 1070px){
  
  .viagemBloco2{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }

  .formGroup, .formGroup2{
    width: 450px;
  }
 }

 @media screen and (max-width: 742px){
  
.viagemBloco3{
  flex-direction: column;
  gap: 20px;
}

.contentArea{
  width: 100%;
}
 }

 @media screen and (max-width: 680px){
  
  .formGroup, .formGroup2{
    width: 400px;
  }
   }

   @media screen and (max-width: 620px){
  
    .formGroup, .formGroup2{
      width: 380px;
    }
     }

     @media screen and (max-width: 595px){
  
      .formGroup, .formGroup2{
        width: 360px;
      }
       }

       
     @media screen and (max-width: 570px){
  
      .formGroup, .formGroup2{
        width: 340px;
      }
       }

       @media screen and (max-width: 550px){
  
        .formGroup, .formGroup2{
          width: 320px;
        }
         }

         @media screen and (max-width: 535px){
  
          .formGroup, .formGroup2{
            width: 300px;
          }
           }
           @media screen and (max-width: 515px){
  
            .formGroup, .formGroup2{
              width: 290px;
            }
             }

             @media screen and (max-width: 500px){
  
              .formGroup, .formGroup2{
                width: 270px;
              }
               }

               @media screen and (max-width: 400px){
  
                .formGroup, .formGroup2{
                  width: 250px;
                }

                .contentArea{
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                }
                 }