.cadastro{
    display: flex; 
    flex-direction: column;  
    align-items: flex-end; 
    background: #F1F3F9; 
}

.mainContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 90px);
    max-height: 20000px;
    min-height: 100vh;
    box-sizing: border-box;
    margin-bottom: 120px;
    
}

.mainContent2{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  width: calc(100% - 320px);
  max-height: 20000px;
  min-height: 100vh;
  box-sizing: border-box;
  margin-bottom: 120px;
  
}

.formBlock{
  background: #ffff;
  padding: 30px;
  width: 90%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

.formBlock3{

  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

:root {

    --input-color: #000;  
    --input-border: #CDD9ED; 
    --input-background: #fff; 
    --input-placeholder: #CBD1DC;
 
    --input-border-focus: #275EFE;  

    --group-color: var(--input-color);
    --group-border: var(--input-border);
    --group-background: #EEF4FF;

    --group-color-focus: #fff;
    --group-border-focus: var(--input-border-focus);
    --group-background-focus: #678EFE;

}
.formField {
    display: block;
    width: 100%;
    padding: 8px 16px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500; 
    font-family: inherit;
    border-radius: 6px;
    /* -webkit-appearance: none; */
    color: var(--input-color);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    transition: border 0.3s ease;
  }
  .formField::-moz-placeholder {
    color: var(--input-placeholder);
  }
  .formField:-ms-input-placeholder {
    color: var(--input-placeholder);
  }
  .formField::placeholder {
    color: var(--input-placeholder);
  }
  .formField:focus {
    outline: none;
    border-color: #18A689;
  }
  
  .formGroup {
    position: relative;
    display: flex;
    width: 350px;
    margin-top: 10px;
  }

  .modalEstilo{
    margin-top: 150px;
}

  .formGroup > span,
  .formGroup .formField {
    white-space: nowrap;
    display: block;
  }
  .formGroup > span:not(:first-child):not(:last-child),
  .formGroup .formField:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .formGroup > span:first-child,
  .formGroup .formField:first-child {
    border-radius: 6px 0 0 6px;
  }
  .formGroup > span:last-child,
  .formGroup .formField:last-child {
    border-radius: 0 6px 6px 0;
  }
  .formGroup > span:not(:first-child),
  .formGroup .formField:not(:first-child) {
    margin-left: -1px;
  }
  .formGroup .formField {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .formGroup > span {
    text-align: center;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 25px;
    color: var(--group-color);
    background: var(--group-background);
    border: 1px solid var(--group-border);
    transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
  }
  .formGroup:focus-within > span {
    color: var(--group-color-focus);
    background: #0D7373;
    border-color: #0D7373;
  }

.form{
    display: flex;
    flex-direction: column;
    margin-top: -200px;
    position: relative;
    z-index: 40;
    gap: 20px;
}

/* .table{
   
  height: 100%;
  max-height: 1500px;
  border: 1px solid black;
  border-radius: 20px;

}

.tableHeader{
  background-color:#18A689;
  color: #fff;

}

.tableName{
  background-color: #18A689;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numbers{
  padding: 20px 10px;
  font-family: 'Inter Tight', sans-serif;
  font-size: 20px;
  
}

.valueTable{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 20px 10px;
  font-family: 'Inter Tight', sans-serif;
  font-size: 20px;
} */

.formBlock h2, .formBlock3 h2{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 22px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 10px;
}


.formComponents{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;
  margin: 10px 0px;
}

.formItems{
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.formButton{
  background: #0d7373;
  border: 1px solid #0d7373;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 150px;
  margin-top: 12px;
}

.blocoSetor{
  width: 90%;
  margin-top: 20px;
}


.loader{
  margin-top: -20px;
  position: absolute;
  z-index: 60;
  background: #ccc;
  width: 100%;
  height: 250vh;
  opacity: 0.8;
  display: flex;
  justify-content: center; 
  align-items: center;
  z-index: 200;
}
.loaderInterno{
opacity: 1;
position: relative;
margin-top: -900px;
z-index: 2000;
}

.buttonEdit{
  background: #ff7f50;
  border: 1px solid #ff7f50;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 120px;
  margin-top: 12px;
}

.buttonClose{
  background: #e61919;
  border: 1px solid #e61919;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 120px;
  margin-top: 12px;
}

.buttonSave{
  background: #0d7373;
  border: 1px solid #0d7373;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 120px;
  margin-top: 12px;
}
.buttonContainer{
  display: flex;
  flex-direction: row;
  gap: 20px;
}