@import url('https://fonts.googleapis.com/css2?family=Spectral:wght@700&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh;
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: block;
  max-width: 100%;
}

button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}