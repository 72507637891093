.sideBar{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  padding-left: 15px; 
  top: 0px;
  left: 0px;
  width: 60px;
  height: 100vh; 
  position: fixed;
  background: #F3F4F6;
  overflow-x: hidden;
  overflow-y: scroll;
  gap: 10px;
  z-index: 200;
  padding-top: 30px;
  transition: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

} 

.sideBar::-webkit-scrollbar {
  width: 12px;             
}

.sideBar::-webkit-scrollbar-track {
  background: #ccc;        /* color of the tracking area */
}

.sideBar::-webkit-scrollbar-thumb {
  background-color: #0D7373;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #0D7373;  /* creates padding around scroll thumb */
}
.menuList{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px;
  width: 100%;
  margin-left: -11px;


}

.menuListItem1{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 31px;
  width: 100%;
  margin-bottom: 100px;
}
.menuIcon{
  display: flex;
  flex-direction: column;
  margin-bottom: 92px;
  cursor: pointer;
  color: white;

}

.menuText{
  padding: 0px;
  color: #000;

}

.menuItens{
    display: flex;

}

.openMenu{

  color: white;
}

.closeIcon{
  display: none;
}


.iconOpen{
  width: 35px;
  height: 35px;
}

.iconClose{
  width: 35px;
  height: 35px;
}

.menuCar{
  width: 26px;
  height: 26px;
  color: #0d7373;
  font-weight: 600px;

}


.users{
  width: 26px;
  height: 26px;
  color: #0d7373;
}



.menuListItem{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  gap: 31px;
  align-items: center;
  height: 40px;
  padding-right: 15px;
  padding-left: 10px;
  width: 160%;


}
.classListMenu{
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
  height: 50px;
  width: 90%;
  padding-left: 5px;
}
.classListMenu:hover{
  background-color: #F6F9FC;
  border-radius: 5px;
}

.menuListItem:hover, .menuListItem4:hover, .menuListItem3:hover{
  background-color: #F6F9FC;
  border-radius: 5px;
  /* padding: 12px;
  margin: -16px; */

}

.menuListItem3{
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  gap: 31px;
  padding-right: 15px;
  padding-left: 10px;
  width: 100%;
  height: 40px;
}

.classSubMenu{
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  gap: 20px;
  width: 100%;
}
.menuListItem4{
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  gap: 31px;
  align-items: center;
  padding-right: 15px;
  padding-left: 10px;
  width: 110%;
  height: 50px;

}

.menuListItem5{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: space-between;
  gap: 31px;
  align-items: center;
  padding-right: 15px;
  padding-left: 10px;
  width: 110%;
  height: 50px;
  visibility: hidden;

}

.test{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: space-between;
  gap: 31px;
  align-items: center;
  padding-right: 15px;
  padding-left: 10px;
  width: 110%;
  height: 50px;

}
#iconViagem, #iconMotorista, #iconVeiculo{
  color: #374151;
  font-size: 22px;
}

#iconAvulsa, #iconMotorista2, #iconGestor{
  color: #374151;
  font-size: 18px;
}
.menuContainerHamb{
  width: 80px;
  padding-left: 5px;
  display: flex;
  cursor: pointer;
}

#menuSVG{
  display: flex;
  font-size: 22px;
  color: #374151 !important;
}

#menuClose{
  font-size: 22px;

}

.menuContainerHamb2{
  display: flex;
}
.icon{
  margin-left: 30px;
}
.menuListItem2{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  gap: 31px;
  align-items: flex-start;
  width: 90%;
  
}


.menuListIcon{
  display: flex;
  flex-direction: row;
  cursor: pointer;
  gap: 40px;
  align-items: center;
}

.menuIconSVG{
  cursor: pointer;
  margin-top: -12px;
 
}



.wallet{
  width: 35px;
  height: 35px;
  margin-top: 7px;
}

.iconSolicitacoes{
  width: 35px;
  height: 35px;
}

.menuListItem NavIcon:hover {
  background-color: #18A689;
}

.iconsBloco{
  font-size: 22px;
}

.menuText, .menuItens, .menuItens2, .menuItens3{
  color: black;

}

.menuItens2{
  font-size: 13px;
}



/* .menuItens:hover{
  background: #fff;
  color: #000;
  padding: 2px 10px;
  border-radius: 5px;
  transition: all ease-out 2s;
} */


.closeMenu{
  display: none;
  transition: all ease-in 200s;

}

.closeContainer{
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: -25px;
  gap: 40px;
  margin-top: -15px;
}

@media screen and (max-width: 450px){
  .sideBar{
 
    height: 60px;
    border-radius: 0px 0px 20px 0px;
    background: #F1F3F9;
  }

  .menuContainerHamb{
    margin-top: -10px;
  }
}

@media screen and (max-width: 300px){
  .sideBar{
 
    height: 60px;
 
  }
}