.col{
  display: flex; 
  gap: 20px;
}
.responsiveTable {
    li {
      border-radius: 3px;
      padding: 25px 30px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }

   
    .tableHeader {
      background-color: #0D7373;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      color: #fff;
      font-weight: 600;
    }
    .tableRow {
      background-color: #fff;
      display: flex;
      align-items: center;
      font-weight: 600;
      box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
      
    }
    #col1 {
      flex-basis: 15%;
    }
    #col2 {
      flex-basis: 50%;
      
    }
    #col3 {
      flex-basis: 35%;
    
    }


    
    @media all and (max-width: 200px) {
      .tableHeader {
        display: none;
      }

      li {
        display: block;
      }
      .col {
        
        flex-basis: 100%;
       
        
      }
      .col {
        display: flex;
        padding: 10px 0;
        &:before {
          color: #6C7A89;
          padding-right: 10px;
          content: attr(data-label);
          flex-basis: 50%;
          text-align: right;
        }
      }
    }
    
  

  @media screen and (max-width: 625px) {
    #col1 {
      display: none;
    }
  
  }
  @media screen and (max-width: 1010px){
    #col1{
      display: none !important;
    }
  }
  
}


@media only screen and (max-width: 1010px) {
  .tableRow, .tableHeader {
    overflow-x: scroll;
  }
 }