:root {

    --input-color: #000;  
    --input-border: #CDD9ED; 
    --input-background: #fff; 
    --input-placeholder: #CBD1DC;
 
    --input-border-focus: #275EFE;  

    --group-color: var(--input-color);
    --group-border: var(--input-border);
    --group-background: #EEF4FF;

    --group-color-focus: #fff;
    --group-border-focus: var(--input-border-focus);
    --group-background-focus: #678EFE;

}
.formField {
    display: block;
    width: 100%;
    padding: 8px 16px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500; 
    font-family: inherit;
    border-radius: 6px;
    /* -webkit-appearance: none; */
    color: var(--input-color);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    transition: border 0.3s ease;
  }
  .formField::-moz-placeholder {
    color: var(--input-placeholder);
  }
  .formField:-ms-input-placeholder {
    color: var(--input-placeholder);
  }
  .formField::placeholder {
    color: var(--input-placeholder);
  }
  .formField:focus {
    outline: none;
    border-color: #18A689;
  }
  
  .formGroup {
    position: relative;
    display: flex;
    width: 315px;
    margin-top: 10px;
  }

  .formGroup > span,
  .formGroup .formField {
    white-space: nowrap;
    display: block;
  }
  .formGroup > span:not(:first-child):not(:last-child),
  .formGroup .formField:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .formGroup > span:first-child,
  .formGroup .formField:first-child {
    border-radius: 6px 0 0 6px;
  }
  .formGroup > span:last-child,
  .formGroup .formField:last-child {
    border-radius: 0 6px 6px 0;
  }
  .formGroup > span:not(:first-child),
  .formGroup .formField:not(:first-child) {
    margin-left: -1px;
  }
  .formGroup .formField {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .formGroup > span {
    text-align: center;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 25px;
    color: var(--group-color);
    background: var(--group-background);
    border: 1px solid var(--group-border);
    transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
  }
  .formGroup:focus-within > span {
    color: var(--group-color-focus);
    background: #0D7373;
    border-color: #0D7373;
  }
  

.cadastro{
    display: flex; 
    flex-direction: column;  
    align-items: flex-end; 
    background: #F1F3F9; 
}

.componentsEmergencia{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.containerEmergencia2{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 30px;
    max-width: 1297px;
    width: 95%;
}

.mainTable{
    margin-top: 40px;
    max-width: 1297px;
    width: 95%;
    border: none;
}

.table{
   
    height: 100%;
    max-height: 1500px;
    border: 1px solid black;
    border-radius: 20px;
    background-color: #F1F3F9;
}

.blocoTitleTable{
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #000;
    margin-bottom: 15px;
}


.tableHeader{
    background-color:#F1F3F9;
    color: #000;

}
.valueTable{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 20px 10px;
    font-family: 'Inter Tight', sans-serif;
    font-size: 20px;
    background-color: #F1F3F9;
}



.tableName{
    background-color: #F1F3F9;
    display: flex;
    justify-content: center;
    align-items: center;
 }

.mainContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 90px);
    max-height: 20000px;
    min-height: 100vh;
    box-sizing: border-box;
    margin-bottom: 120px;
    
}

.mainContent2{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    width: calc(100% - 320px);
    max-height: 20000px;
    min-height: 100vh;
    box-sizing: border-box;
    margin-bottom: 120px;
    
}
.messagemErro{
    font-family: 'Inter Tight', sans-serif;
    color: #cc0000;
}

.btnCadastro:hover{
    color: #18A689;
    background-color: white;
    border: 1px solid #18A689;
}

.btnCadastro:hover p{
    color: #18A689;
}

.btnText{
    color: white;
}

.btnVoltar{
    border: 1px solid #18A689;
    background-color: white;
}

.btnVoltar:hover{
    background-color: #18A689;
}

.btnVoltar:hover p{
    color: white;
}

.formBlock{
    background: #ffff;
    padding: 30px;
    width: 90%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
}

.formButton{
    background: #0d7373;
    border: 1px solid #0d7373;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 100px;
    margin-top: 12px;
}

.formButton2{
    background: #0d7373;
    border: 1px solid #0d7373;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 22px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 50px;
    margin-top: 10px;
}
.formButton2Excedent{
    background: #ccc;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 22px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 50px;
    margin-top: 10px;
}

.loader{
    margin-top: -20px;
    position: absolute;
    z-index: 60;
    background: #ccc;
    width: 100%;
    height: 250vh;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
  }
  .loaderInterno{
  opacity: 1;
  position: relative;
  margin-top: -900px;
  z-index: 2000;
  }


 .blocoEmergencia{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
 
 }

 .blocoEmergencia2{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    gap: 30px;
 }

 .blocoExterno{
    display: flex;
    flex-direction: column;
    gap: 20px;
 }
 .loaderInterno{
  opacity: 1;
  position: relative;
  margin-top: -900px;
  z-index: 2000;
}

.formButton3{
    background: #0d7373;
    border: 1px solid #0d7373;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif; 
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 200px;

}

.components{
    display: flex;
    flex-direction: column;
    gap:30px;
    width: 100%;
}


.formComponents{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
    margin: 20px 0px;
  
}

.formComponents2{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 20px;
}
 

.formItems2{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-left: 140px;
}
.form{
    margin-top: -200px;
    position: relative;
    z-index: 40;
}

.formInputFile{
    width: 200px;
}
.inputForm, .inputFormTelefone{

    width: 315px !important; 
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}

.inputForm2{
    width: 360px !important;
}
.formBlock h2{
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 22px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 10px;
}
.labels{
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}
.modalEstilo{
    margin-top: 200px;
}

.formButtonCadastro{
    margin-top: 120px;
    display: flex;
    justify-content: flex-end;
 
}
.formItems{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.formOption{
    max-width: 161px;
    width: 100%;
    height: 63px;
    background: #FFFFFF;
    border: 1px solid #C7C7C7;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0);
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: none;
    z-index: 1;
    position: relative;
    
}

.formOption2{
    max-width: 221px;
    width: 100%;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #C7C7C7;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0);
    border-radius: 10px 10px 0px 0px;
    border-bottom: none;
    z-index: 1;
    position: relative;
    
  

}

.options{
    display: flex;
    margin-top: 39px;
}

.formOption p, .formOption2 p{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  
}

.formCadastro{
    display: flex;
    flex-direction: column;
    gap: 16px;
}


/* Mobile - Motorista*/

@media screen and (max-width: 1338px){
    .formGroup{
        width: 300px !important;
    }
}


@media screen and (max-width: 1285px){
    .formGroup{
        width: 290px !important;
    }
}

@media screen and (max-width: 1256px){
    .formGroup{
        width: 270px !important;
    }
}

@media screen and (max-width: 1190px){
    .formGroup{
        width: 260px !important;
    }
}

@media screen and (max-width: 1152px){
    .blocoEmergencia{
        flex-direction: column;
    }
}

@media screen and (max-width: 770px){
    .formGroup{
        width: 240px !important;
    }
}

@media screen and (max-width: 725px){
    .formGroup{
        width: 220px !important;
    }
}

@media screen and (max-width: 690px){
    .formGroup{
        width: 200px !important;
    }
}

@media screen and (max-width: 635px){
 .formComponents, .formComponents2{
    flex-direction: column;
    align-items: center;

 }
 .formGroup{
    width: 290px !important;
}
.formButton{
    margin-top: -20px;
    width: 290px;
}
}

@media screen and (max-width: 620px){

    .formGroup{
       width: 270px !important;
   }

   .formButton{
    width: 270px;
}
   }

   @media screen and (max-width: 500px){
    .mainContent{
        margin-top: 20px;
    }
   }

   @media screen and (max-width: 462px){
            .formGroup{
                width: 240px !important;
            }
        
            .formButton{
            width: 240px;
        }

        .formButton{
            width: 240px;
        }

        .mainContent{
            width: calc(100% - 20px);
        }

        .blocoEmergencia2{ 
            flex-direction: column;
            align-items: flex-start;
        }
   }



/* Mobile - Motorista*/


