:root {

  --input-color: #000;
  --input-border: #CDD9ED; 
  --input-background: #fff; 
  --input-placeholder: #CBD1DC; 

  --input-border-focus: #275EFE; 

  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #EEF4FF;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: #678EFE;

}
.formField {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  /* -webkit-appearance: none; */
  color: var(--input-color);
  border: 1px solid var(--input-border);
  background: var(--input-background);
  transition: border 0.3s ease;
}
.formField::-moz-placeholder {
  color: var(--input-placeholder);
}
.formField:-ms-input-placeholder {
  color: var(--input-placeholder);
}
.formField::placeholder {
  color: var(--input-placeholder);
}
.formField:focus {
  outline: none;
  border-color: #18A689;
}

.formGroup {
  position: relative;
  display: flex;
  width: 380px;
  margin-top: 10px;
}

.formGroup2 {
  position: relative;
  display: flex;
  width: 250px;
  margin-top: 10px;
}

.formGroup3 {
  position: relative;
  display: flex;
  width: 160px;
  margin-top: 10px;
}

.formGroup4 {
  position: relative;
  display: flex;
  width: 320px;
  margin-top: 10px;
}

.formGroup > span,  .formGroup2 > span, .formGroup3 > span, .formGroup4 > span,
.formGroup .formField,  .formGroup2 .formField, .formGroup3 .formField, .formGroup4 .formField {
  white-space: nowrap;
  display: block;
}
.formGroup > span:not(:first-child):not(:last-child), .formGroup2 > span:not(:first-child):not(:last-child), .formGroup3 > span:not(:first-child):not(:last-child), .formGroup4 > span:not(:first-child):not(:last-child),
.formGroup .formField:not(:first-child):not(:last-child), .formGroup2 .formField:not(:first-child):not(:last-child), .formGroup3 .formField:not(:first-child):not(:last-child), .formGroup4 .formField:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.formGroup > span:first-child, .formGroup2 > span:first-child,  .formGroup3 > span:first-child, .formGroup4 > span:first-child,
.formGroup .formField:first-child, .formGroup2 .formField:first-child, .formGroup3 .formField:first-child, .formGroup4 .formField:first-child {
  border-radius: 6px 0 0 6px;
}
.formGroup > span:last-child,.formGroup2 > span:last-child, .formGroup3 > span:last-child, .formGroup4 > span:last-child,
.formGroup .formField:last-child, .formGroup2 .formField:last-child, .formGroup3 .formField:last-child, .formGroup4 .formField:last-child {
  border-radius: 0 6px 6px 0;
}
.formGroup > span:not(:first-child), .formGroup2 > span:not(:first-child), .formGroup3 > span:not(:first-child), .formGroup4 > span:not(:first-child),
.formGroup .formField:not(:first-child), .formGroup2 .formField:not(:first-child), .formGroup3 .formField:not(:first-child), .formGroup4 .formField:not(:first-child) {
  margin-left: -1px;
}
.formGroup .formField, .formGroup2 .formField, .formGroup3 .formField, .formGroup4 .formField {
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  width: 1%;
  margin-top: 0;
  margin-bottom: 0;
}
.formGroup > span, .formGroup2 > span, .formGroup3 > span, .formGroup4 > span {
  text-align: center;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 25px;
  color: var(--group-color);
  background: var(--group-background);
  border: 1px solid var(--group-border);
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}
.formGroup:focus-within > span, .formGroup2:focus-within > span,  .formGroup3:focus-within > span, .formGroup4:focus-within > span {
  color: var(--group-color-focus);
  background: #0D7373;
  border-color: #0D7373;
}

.mainForm h2{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 22px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 30px;
}
.paginationList{
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: flex-end;

}
.paginationItem{
  width: 40px; 
  background-color: #0D7373;
  text-align: center;
  color: #fff;
  font-size: 17px;
  border-radius: 30%;
  cursor: pointer;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.formBlock h2{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 22px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 30px;
  margin-top: 30px;
}
.viagem{
  display: flex;
  flex-direction: column;  
  align-items: flex-end;   
  background: #F1F3F9;
  height: 100%;
  padding-bottom: 50px;


}
.mainContent{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 90px);
  min-height: 100vh;
  max-height: 2000px;
  box-sizing: border-box;
  padding-bottom: 23px;

}

.blocoButtons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mainFormItemA{ 
  display: flex;
  flex-direction: row;
  gap: 27px;


}

/* input::-webkit-calendar-picker-indicator{
  display: block;
} */
.inputAlter{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 300px !important;
  height: 36px;
  padding: 15px 0px 15px 7px;

}

.viagemEstilo{
  font-size: 20px;
  font-family: 'Inter Tight', sans-serif;
}

.modalEstilo{
  margin-top: 150px;
}
.input{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0); 
  border-radius: 5px;
  width: 220px !important;
  height: 42px;
  padding: 15px 0px 15px 7px;

}

.inputTwo{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 100px !important;
  height: 42px;
  padding: 15px 0px 15px 7px;
}

.loader{
  margin-top: -20px;
  position: absolute;
  z-index: 60;
  background: #ccc;
  width: 100%;
  height: 250vh;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.loaderInterno{
opacity: 1;
position: relative;
margin-top: -900px;
z-index: 2000;
}

.inputThree{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 185px !important;
  height: 42px;
  padding: 15px 0px 15px 7px;
}

.labels{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}


.mainForm{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 40px;
  /* background-color: #FFFFFF; */
  background: #ffff;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  top: -140px;


}



.inputIcon{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.img{
  width: 24px;
  height: 24px;
}

.inputForm{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.formInputBlock{
  display: flex;
  flex-direction: row;
  gap: 27px;
  margin-top: 30px;
}

.formBlockLocal{
  display: flex;
  flex-direction: row;
  gap: 27px;
  align-items: flex-end;
  margin-top: 15px;
}

.mainTable{
  margin-top: -145px;
  max-width: 1297px;
  width: 95%;
  border: none;
}

.tableName{
 background-color: #ffff;
 display: flex;
 justify-content: center;
 align-items: center;
}

.table{
 
  height: 100%;
  max-height: 1500px;
  border: 1px solid black;
  border-radius: 20px;

}

.tableHeader{
  background-color:#ffff;
  color: #000;

}


.contentArea{
  max-width: 1297px;
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  /* background-color: #fff; */
  background: #ffff;
  padding: 20px;
  border-radius: 10px;

}

.contentArea h2{
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.textArea{
  height: 150px;
}


.contentAreaBtn {
  background: #0d7373;
border: 1px solid #0d7373;
border-radius: 6px;
box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
box-sizing: border-box;
color: #FFFFFF;
cursor: pointer;
display: inline-block;
font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
font-size: 16px;
font-weight: 800;
line-height: 16px;
min-height: 40px;
outline: 0;
padding: 12px 14px;
text-align: center;
text-rendering: geometricprecision;
text-transform: none;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
vertical-align: middle;
width: 300px;
margin-top: 30px;
}
.active{
  background-color: #000;
}

.contentAreaBtn2 {
  background: #e61919;
border: 1px solid #e61919;
border-radius: 6px;
box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
box-sizing: border-box;
color: #FFFFFF;
cursor: pointer;
display: inline-block;
font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
font-size: 16px;
font-weight: 800;
line-height: 16px;
min-height: 40px;
outline: 0;
padding: 12px 14px;
text-align: center;
text-rendering: geometricprecision;
text-transform: none;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
vertical-align: middle;
width: 300px;
margin-top: 30px;
}


.deleteIcon{
  width: 16px;
  height: 16px;
  transform: rotate(90deg);

}


.itemsTD{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  padding: 10px 0px;
}

.textTD{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.textP{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.mainForm{
  max-width: 1297px;
  width: 95%;

}

.mainFormItemB{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  gap: 25px;
}

.select, .select2{
  width: 315px !important;
  height: 43px;
  background: #FFFFFF;
  border: 1px solid #787575;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
  border-radius: 5px;
  padding-left: 10px;
}

.select::-ms-expand {
  display: none;
}

/* .itemButtonEdit{
  width: 90px;
  height: 40px;
  background-color: #ff8c00;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 550;
  cursor: poiter;
  display: flex;
  align-items: center;
  justify-content: center;
} */


.itemButtonEdit {
  background: #fa6400;
border: 1px solid #fa6400;
border-radius: 6px;
box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
box-sizing: border-box;
color: #FFFFFF;
cursor: pointer;
display: inline-block;
font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
font-size: 16px;
font-weight: 800;
line-height: 16px;
min-height: 40px;
outline: 0;
padding: 12px 14px;
text-align: center;
text-rendering: geometricprecision;
text-transform: none;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
vertical-align: middle;
} 

/* .itemButtonEdit:hover,
.itemButtonEdit:active {
background-color: initial;
background-position: 0 0;
color: linear-gradient(to bottom right, #EF4765, #FF9A5A)
} */

/* .itemButtonEdit:active {
opacity: .5;
} */
/* .itemButtonSave{
  width: 90px;
  height: 40px;
  background-color: #008000;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 550;
  cursor: poiter;
  display: flex;
  align-items: center;
  justify-content: center;
} */



.itemButtonSave {
background: #2ea44f;
border: 1px solid #2ea44f;
border-radius: 6px;
box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
box-sizing: border-box;
color: #FFFFFF;
cursor: pointer;
display: inline-block;
font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
font-size: 16px;
font-weight: 800;
line-height: 16px;
min-height: 40px;
outline: 0;
padding: 12px 14px;
text-align: center;
text-rendering: geometricprecision;
text-transform: none;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
vertical-align: middle;
}

.formBlockLocalTwo{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
}

/* .itemButtonSave:hover,
.itemButtonSave:active {
background-color: initial;
background-position: 0 0;
color: #2ea44f;
}

.itemButtonSave:active {
opacity: .5;
} */



/* .itemButtonExc{
  width: 90px;
  height: 40px;
  background-color: #ff0000;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 550;
  cursor: poiter;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.formBlockLocalOne{
  display: flex;
  gap: 15px;
}


.itemButtonExc {
background: #FF4742;
border: 1px solid #FF4742;
border-radius: 6px;
box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
box-sizing: border-box;
color: #FFFFFF;
cursor: pointer;
display: inline-block;
font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
font-size: 16px;
font-weight: 800;
line-height: 16px;
min-height: 40px;
outline: 0;
padding: 12px 14px;
text-align: center;
text-rendering: geometricprecision;
text-transform: none;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
vertical-align: middle;
}

.valueTable{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 20px 10px;
  font-family: 'Inter Tight', sans-serif;
  font-size: 20px;
}

.selectOption{
  border-radius: 10px;
}

.btn{
  width: 48px;
  height: 43px;
  background: #0D7373;
  border: 1px solid #0D7373;
  cursor: poiter;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  margin-left: 20px;
}



.modalList{
  margin: 0 auto;
}


.mainFormItemC{
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
}

.titlePage{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #333333;
}

.inputParadas{
  padding: 5px 20px;
  width: 280px;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  background: #fff;
  border: 1px solid #808080;
}

@media screen and (max-width: 920px){
  .mainFormItemB{
      flex-direction: row;
      margin-top: 20px;
  }
  

  .mainForm{
      display: flex;
      align-items: flex-start;
  }

  .formBlockLocal{
      flex-direction: row;
      margin-top: 0px;
      margin-top: 20px;
  }
  .mainFormItemC{
      margin: 15px 0px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start;
      align-items: flex-start;
  }


  
}

tbody{
  background-color: #fff;
}

.inputFormCheck{
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: center;

}
.numbers{
  padding: 20px 10px;
  font-family: 'Inter Tight', sans-serif;
  font-size: 20px;
  
}

option[value=""][disabled] {
  display: none;
}



/* Mobile - Tela de Criação de Viagem Avulsa*/

@media screen and (max-width: 1096px) {
  .formGroup{
      width: 330px;
  }

  .formGroup4{
      width: 300px;
  }

  .formGroup3{
      width: 150px;
  }

  .formGroup2{
      width: 175px;
  }
}

@media screen and (max-width: 1040px) {
  .formGroup{
      width: 310px;
  }

  .formGroup4{
      width: 280px;
  }

  .formGroup3{
      width: 140px;
  }

  .formGroup2{
      width: 165px;
  }
}

@media screen and (max-width: 1000px) {
  .formGroup{
      width: 300px;
  }

  .formGroup4{
      width: 622px;
  }

  .formGroup3{
      width: 160px;
  }

  .formGroup2{
      width: 180px;
  }

  .mainFormItemA{
      width: 85%;
      flex-wrap: wrap;
  }

  .mainFormItemB{
      width: 85%;
  }

  .formBlockLocal{
      width: 85%;
      flex-wrap: wrap;
      justify-content: flex-start;
  
  }
}

@media screen and (max-width: 855px) {
  .formGroup{
      width: 260px;
  }

  .formGroup4{
      width: 545px;
  }

  .formGroup3{
      width: 150px;
  }

  .formGroup2{
      width: 160px;
  }

  .mainFormItemA{
      width: 85%;
      flex-wrap: wrap;
  }

  .mainFormItemB{
      width: 85%;
  }

  .formBlockLocal{
      width: 85%;
      flex-wrap: wrap;
      justify-content: flex-start;
  
  }
}

@media screen and (max-width: 775px) {
  .formGroup{
      width: 300px;
  }

  .formGroup4{
      width: 300px;
  }

  .formGroup3{
      width: 300px;
  }

  .formGroup2{
      width: 300px;
  }

  .mainFormItemA{
      width: 85%;
      flex-direction: column;
  }

  .mainFormItemB{
      width: 85%;
      flex-direction: column;
      align-items: flex-start;
  }

  .formBlockLocal{
     flex-direction: column;
     align-items: flex-start;

  
  }

  .formBlockLocalOne{
      flex-direction: column;
  }

  .viagem{
    display: flex;
    flex-direction: column;  
    align-items: flex-end;   
    background: #F1F3F9;
    height: 2700px;
  
  
  }
}

@media screen and (max-width: 774px){
  
  .contentAreaBtn2, .contentAreaBtn {

   width: 250px;

  }
}

@media screen and (max-width: 672px){
  
  .contentAreaBtn2, .contentAreaBtn {

   width: 220px;

  }
}

@media screen and (max-width: 610px){
  
  .contentAreaBtn2, .contentAreaBtn {

   width: 200px;

  }
}

@media screen and (max-width: 566px){
  
  .contentAreaBtn2, .contentAreaBtn {

   width: 300px;

  }
  .blocoButtons{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 465px) {
  .formGroup{
      width: 250px;
  }

  .formGroup4{
      width: 250px;
  }

  .formGroup3{
      width: 250px;
  }

  .formGroup2{
      width: 250px;
  }

 
}

@media screen and (max-width: 451px) {
  .formGroup{
      width: 280px;
  }

  .formGroup4{
      width: 280px;
  }

  .formGroup3{
      width: 280px;
  }

  .formGroup2{
      width: 280px;
  }

  .mainContent{
      width: calc(100% - 30px);
  }

 
}

@media screen and (max-width: 377px) {
  .formGroup{
      width: 260px;
  }

  .formGroup4{
      width: 260px;
  }

  .formGroup3{
      width: 260px;
  }

  .formGroup2{
      width: 260px;
  }

  .mainContent{
      margin-top: 20px;
  }

 
}



/* Mobile - Tela de Criação de Viagem Avulsa*/
